import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';



const TemplateCetak = ({ url, content }) => {

  const [level, setLevel] = useState('');
  const [jurusan, setJurusan] = useState([]);
  const [selectedJurusan, setSelectedJurusan] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rombel, setRombel] = useState([]);
  const [listMapel, setListMapel] = useState([])
  const [listMapelModal, setListMapelModal] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [tpId, setTpId] = useState('');
  const [tpNonAktif, setTpNonAktif] = useState([]);
  const [kelompok, setKelompok] = useState([])
  const [selectedKelompok, setSelectedKelompok] = useState('');
  const [urut, setUrut] = useState('');
  const [mapel, setMapel] = useState([]);
  const [selectedMapel, setSelectedMapel] = useState('');


  const fetchJurusan = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "jurusan", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setJurusan(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };





  const fetchMapel = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "mapel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMapel(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchKelompok = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "kelompok-mapel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setKelompok(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRombelbyId = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading
    try {
      const response = await axios.post(url + "rombel-by-jurusan", {
        jurusan_id: selectedJurusan,
        level_id: level
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRombel(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // End loading
    }
  };


  const fetchListMapel = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading
    try {
      const response = await axios.post(url + "tpl-by-lvl-jur", {
        jurusan_id: selectedJurusan,
        level_id: level
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setListMapel(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // End loading
    }
  };


  const fetchTpNonAktif = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "tahun-pelajaran-nonaktif", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTpNonAktif(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchListMapelModal = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading
    try {
      const response = await axios.post(url + "tpl-by-lvl-jur-tp", {
        jurusan_id: selectedJurusan,
        level_id: level,
        tp: tpId
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setListMapelModal(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // End loading
    }
  };




  useEffect(() => {
    fetchJurusan();
    fetchKelompok();
    fetchMapel();
    fetchTpNonAktif();

  }, []);

  useEffect(() => {
    setSelectedJurusan('');
    setRombel([]);
  }, [level]);


  useEffect(() => {
    fetchRombelbyId(selectedJurusan, level);
    fetchListMapel(selectedJurusan, level);

  }, [selectedJurusan, level]);


  useEffect(() => {
    fetchListMapelModal(selectedJurusan, level, tpId);
  }, [selectedJurusan, level, tpId]);


  const handleCopyTemplate = () => {
    setShowModal(true);
  }

  const handleAdd = () => {
    setShowModalAdd(true);
  }


  const handleCopy = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      // console.log('tp', tpId)

      const response = await axios.post(url + "copy-template", {
        tp: tpId,

      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response);
      setShowModal(false);
      toast.success("Berhasil Menyalin Template Cetak");
      await fetchListMapel()

    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axios.post(url + "del-template", {
        id: id
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response);
      toast.success("Berhasil Menghapus Template");
      await fetchListMapel();
    } catch (error) {
      console.log(error);
      toast.error("Gagal Menghapus Template");
    } finally {
      setIsLoading(false);
    }
  };


  const handleAddTemplate = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      console.log('level', level)
      console.log('jurusan', selectedJurusan)
      console.log('kelompok', selectedKelompok)
      console.log('urut', urut)
      console.log('mapel', selectedMapel)
      const response = await axios.post(url + "add-template", {
        lvl: level,
        jur: selectedJurusan,
        kelompok: selectedKelompok,
        urutan: urut,
        mp: selectedMapel,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast.success("Berhasil Menambahkan Template");
      await fetchListMapel();
      setShowModalAdd(false)
    } catch (error) {
      console.log(error);
      toast.error("Gagal Menghapus Template");
    } finally {
      setIsLoading(false);
    }
  };

  const groupedItems = listMapel.reduce((acc, item) => {
    const group = kelompok.find(k => k.id_klpk_mp === Number(item.kelompok));
    const groupName = group ? `${group.huruf} ${group.nama_kelompok}` : 'Unknown';
    if (!acc[groupName]) {
      acc[groupName] = [];
    }
    acc[groupName].push(item);
    return acc;
  }, {});


  const groupedItemsModal = listMapelModal.reduce((acc, item) => {
    const groupModal = kelompok.find(k => k.id_klpk_mp === Number(item.kelompok));
    const groupNameModal = groupModal ? `${groupModal.huruf} ${groupModal.nama_kelompok}` : 'Unknown';
    if (!acc[groupNameModal]) {
      acc[groupNameModal] = [];
    }
    acc[groupNameModal].push(item);
    return acc;
  }, {});



  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">

            <div className="card-header">
              <div className="row">
                <div className="col-md-6">
                  <select className="form-control" onChange={(e) => setLevel(e.target.value)}>
                    <option value="">Pilih Level / Kelas</option>
                    <option value="1">10</option>
                    <option value="2">11</option>
                    <option value="3">12</option>
                  </select>
                </div>
                <div className="col-md-6">
                  <select className='form-control' value={selectedJurusan} onChange={(e) => setSelectedJurusan(e.target.value)}>
                    <option value="">Pilih Jurusan</option>
                    {jurusan.map((j) => (
                      <option key={j.id} value={j.id}>{j.jurusan}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>


            <div className="card-body">

              <div className="col-md-6 mb-3">
                {level && selectedJurusan && (
                  <div className="btn-group btn-group-pill mt-2" role="group" aria-label="Basic example">
                    <button className="btn btn-primary sm-btn" type="button" onClick={handleAdd} title="">Tambah</button>
                    <button className="btn btn-success sm-btn" type="button" title="" onClick={handleCopyTemplate}>Salin Template</button>
                    <NavLink to={`/reff-kelompok-mapel`} className="btn btn-info sm-btn" type="button" >Reff Kelompok Mapel</NavLink>

                  </div>
                )}
              </div>

              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>

                        <th>Urutan</th>
                        <th>Item Mapel</th>
                        <th>Action</th>

                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="2" className="text-center">Loading...</td>
                        </tr>
                      ) : (
                        Object.keys(groupedItems).map((groupName, groupIndex) => (
                          <React.Fragment key={groupIndex}>
                            <tr>
                              <td colSpan="4"><b>{groupName}</b></td>
                            </tr>
                            {groupedItems[groupName].map((item, index) => (
                              <tr key={index}>

                                <td>{item.urutan}</td>
                                <td>{item.nama_mp}</td>


                                <td>
                                  <button className='btn btn-danger sm-btn' onClick={() => handleDelete(item.id)}>Delete</button>

                                </td>
                              </tr>
                            ))}
                          </React.Fragment>
                        ))
                      )}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Salin Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleCopy}>
            <p>Copy Template dari Tahun Pelajaran</p>
            <select className='form-control mt-2' onChange={(e) => setTpId(e.target.value)} required>
              <option value="">Pilih Tahun Pelajaran</option>
              {tpNonAktif.map((tp) => (
                <option key={tp.id} value={tp.id}>{tp.thn_pel}</option>
              ))}
            </select>
            <hr />
            <div className="table-responsive">
              <table className="table table-styled mb-0">
                <thead>
                  <tr>
                    <th>Urutan</th>
                    <th>Item Mapel</th>

                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="2" className="text-center">Loading...</td>
                    </tr>
                  ) : (
                    Object.keys(groupedItemsModal).map((groupNameModal, groupIndex) => (
                      <React.Fragment key={groupIndex}>
                        <tr>
                          <td colSpan="4"><b>{groupNameModal}</b></td>
                        </tr>
                        {groupedItemsModal[groupNameModal].map((item, index) => (
                          <tr key={index}>

                            <td>{item.urutan}</td>
                            <td>{item.nama_mp}</td>

                          </tr>
                        ))}
                      </React.Fragment>
                    ))
                  )}

                </tbody>
              </table>
            </div>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Copy'}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>


      <Modal show={showModalAdd} onHide={() => setShowModalAdd(false)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Add Item Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleAddTemplate}>
            <p>Level / Kelas</p>
            <input type='text' className='form-control' value={
              level === '1' ? 'Kelas 10' :
                level === '2' ? 'Kelas 11' :
                  level === '3' ? 'Kelas 12' : ''
            } readOnly required />

            <div className="mt-3">
              <p>Jurusan</p>
              <select className='form-control' value={selectedJurusan} onChange={(e) => setSelectedJurusan(e.target.value)} disabled required>
                <option value="">Pilih Jurusan</option>
                {jurusan.map((j) => (
                  <option key={j.id} value={j.id}>{j.jurusan}</option>
                ))}
              </select>
            </div>

            <div className="mt-3">
              <p>Pilih Kelompok Mapel</p>
              <select className='form-control' value={selectedKelompok} onChange={(e) => setSelectedKelompok(e.target.value)} required>
                <option value="">Pilih Kelompok Mapel</option>
                {kelompok.map((k) => (
                  <option key={k.id_klpk_mp} value={k.id_klpk_mp}>{k.huruf} {k.nama_kelompok}</option>
                ))}
              </select>
            </div>

            <div className="mt-3">
              <p>Urutan</p>
              <input type="text" className='form-control' value={urut}
                onChange={(e) => setUrut(e.target.value)} required />
            </div>
            <div className="mt-3">
              <p>Pilih Mapel</p>
              <select className='form-control' value={selectedMapel} onChange={(e) => setSelectedMapel(e.target.value)} >
                <option value="">Pilih  Mapel</option>
                {mapel.map((m) => (
                  <option key={m.id} value={m.kode_mp}>{m.nama_mp}</option>
                ))}
              </select>
            </div>



            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModalAdd(false)}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Simpan'}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>


    </div>
  )
}

export default TemplateCetak
