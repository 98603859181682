import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Navigate,
} from "react-router-dom";
import DashboardAdmin from "./pages/DashboardAdmin";
import Settings from "./pages/Settings";
import Auth from "./components/login/Auth";
import Masters from "./pages/Masters";
import Detail from "./pages/Detail";

import 'font-awesome/css/font-awesome.min.css';
import KenaikanKelas from "./pages/KenaikanKelas";
import DashboardGuru from "./pages/DashboardGuru";
import Penilaian from "./pages/Penilaian";
import InputNilai from "./pages/InputNilai";
import Kkm from "./pages/Kkm";
import UploadNilai from "./pages/UploadNilai";
import Absensi from "./pages/Absensi";
import Ekstrakurikuler from "./pages/Ekstrakurikuler";
import InputNilaiEkstra from "./pages/InputNilaiEkstra";
import InputNilaiEkstraPilihan from "./pages/InputNilaiEkstraPilihan";
import StatusNilaiWakel from "./pages/StatusNilaiWakel";
import Rapor from "./pages/Rapor";
import Template from "./pages/Template";
import Kelompok from "./pages/Kelompok";
import Tugas from "./pages/Tugas";
import Tanggal from "./pages/Tanggal";
import Legger from "./pages/Legger";
import StatusNilaiAdmin from "./pages/StatusNilaiAdmin";
import DknPerKelas from "./pages/DknPerKelas";
import PindahKelas from "./pages/PindahKelas";
import Riwayat from "./pages/Riwayat";
import CtkRapor from "./pages/CtkRapor";


function App() {
  // const url = 'https://dev.smkturen.web.id/api/'
  const url = 'https://api.smkturen.web.id/api/'
  // const url = 'http://103.13.206.72/api/public/api/'
  // const url = 'http://api-erapor.cybermmc.my.id/api/';
  // const url = 'http://localhost:8000/api/';
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/dashboard" element={<DashboardAdmin url={url} />} />
        <Route path="/dashboard-guru" element={<DashboardGuru url={url} />} />
        <Route path="/tahun-pelajaran" element={<Settings content={'Tahun Pelajaran'} url={url} />} />
        <Route path="/semester" element={<Settings content={'Semester'} url={url} />} />
        <Route path="/set-kurikulum" element={<Settings content={'Set Kurikulum'} url={url} />} />
        <Route path="/mode-rapor" element={<Settings content={'Mode Rapor'} url={url} />} />
        <Route path="/master-siswa" element={<Masters content={'Data Siswa'} url={url} />} />
        <Route path="/master-guru" element={<Masters content={'Data Guru'} url={url} />} />
        <Route path="/master-jurusan" element={<Masters content={'Data Jurusan'} url={url} />} />
        <Route path="/master-rombel" element={<Masters content={'Data Rombel / Kelas'} url={url} />} />
        <Route path="/master-mapel" element={<Masters content={'Data Mata Pelajaran'} url={url} />} />
        <Route path="/kakomli" element={<Masters content={'Kakomli'} url={url} />} />
        <Route path="/kenaikan-kelas" element={<KenaikanKelas url={url} />} />
        <Route path="/detail-rombel/:nama_rombel" element={<Detail url={url} />} />
        <Route path="/kkm" element={<Kkm url={url} />} />
        <Route path="/template-cetak" element={<Template url={url} />} />
        <Route path="/reff-kelompok-mapel" element={<Kelompok url={url} />} />
        <Route path="/tugas-guru" element={<Tugas url={url} />} />
        <Route path="/penanggalan-rapor" element={<Tanggal url={url} />} />
        <Route path="/status-nilai" element={<StatusNilaiAdmin url={url} />} />
        <Route path="/dkn-perkelas" element={<DknPerKelas url={url} type={'kelas'} />} />
        <Route path="/dkn-perjurusan" element={<DknPerKelas url={url} type={'jurusan'} />} />
        <Route path="/dkn-jurusan/:level/:selectedJurusan/:jur_id" element={<DknPerKelas url={url} type={'kakomli'} />} />
        <Route path="/pindah-kelas" element={<PindahKelas url={url} />} />
        <Route path="/riwayat-akademik" element={<Riwayat url={url} />} />
        <Route path="/cetakrapor" element={<CtkRapor url={url} />} />



        {/* Proses Penilaian */}
        <Route path="/penilaian/:kode_mp/:nama_rombel" element={<Penilaian url={url} />} />
        <Route path="/guru/input-nilai/:kode_mp/:nama_rombel/:kurikulum" element={<InputNilai url={url} />} />
        <Route path="/guru/upload-nilai/:kode_mp/:nama_rombel/:kurikulum" element={<UploadNilai url={url} />} />
        <Route path="/guru/absensi/:nama_rombel" element={<Absensi url={url} />} />
        <Route path="/guru/ekstrakurikuler/:nama_rombel" element={<Ekstrakurikuler url={url} />} />
        <Route path="/guru/status-nilai/:nama_rombel" element={<StatusNilaiWakel url={url} />} />
        <Route path="/guru/rapor/:nama_rombel" element={<Rapor url={url} />} />
        <Route path="/legger/:nama_rombel" element={<Legger url={url} />} />

        <Route path="/wakel/input-ekstra/:id_ekstra/:nama_rombel" element={<InputNilaiEkstra url={url} />} />
        <Route path="/wakel/input-ekstra-pilihan/:id_ekstra/:nama_rombel" element={<InputNilaiEkstraPilihan url={url} />} />
      </Routes>
    </Router>
  );
}

export default App;
