import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Button, Modal, Form } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import jsPDF from 'jspdf';
import imgData from './kop.jpg';
import 'jspdf-autotable';
import { PDFDocument } from 'pdf-lib';
import html2canvas from 'html2canvas';
import he from 'he';
import '@canvas-fonts/times-new-roman/Times New Roman.ttf';

const Raport = ({ url, content }) => {
  const { nama_rombel } = useParams();
  const [siswa, setSiswa] = useState([])
  const [loading, setLoading] = useState(false)
  const [template, setTemplate] = useState([])
  const [rombel, setRombel] = useState([])
  const [jurusan, setJurusan] = useState([])
  const [semester, setSemester] = useState([])
  const [program, setProgram] = useState('')
  const [tampilSmt, setTampilSmt] = useState('')
  const [kelompok, setKelompok] = useState([])
  const [loadAll, setLoadAll] = useState(false)
  const [loadcetak, setLoadcetak] = useState(false)
  const [setting, setSetting] = useState([])
  const [kepsek, setKepsek] = useState([])
  const [fase, setFase] = useState('')
  const [kurikulum, setKurikulum] = useState([]);
  const [mode, setMode] = useState([]);
  const [level, setLevel] = useState([]);


  const fetchKurikulum = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        url + "curr-by-level",
        {
          level: level,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setKurikulum(response.data.data[0].kurikulum);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMode = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        url + "mode-aktif",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMode(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };




  const fetchSiswa = async () => {
    setLoading(true)
    const token = localStorage.getItem('token')
    const response = await axios.post(url + 'siswa-by-rombel', {
      rombel_nama: nama_rombel
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    setSiswa(response.data.data)
    setLoading(false)
  }


  const fetchSemester = async () => {
    setLoading(true)
    const token = localStorage.getItem('token')
    const response = await axios.get(url + 'semester-aktif', {
      headers: { Authorization: `Bearer ${token}` }
    })
    setSemester(response.data.data)
    setLoading(false)
  }

  const fetchRombel = async () => {
    setLoading(true)
    const token = localStorage.getItem('token')
    const response = await axios.post(url + 'rombel-by-name', {
      nama_rombel: nama_rombel
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    setRombel(response.data.data)
    setLevel(response.data.data.level_id)
    setLoading(false)
  }

  const fetchJurusan = async () => {
    setLoading(true)
    const token = localStorage.getItem('token')
    const response = await axios.post(url + 'jurusan-by-id', {
      id: rombel.jurusan_id
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    setJurusan(response.data.data)
    setLoading(false)
  }

  const fetchKelompok = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "kelompok-mapel", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setKelompok(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTemplate = async () => {
    setLoading(true)
    const token = localStorage.getItem('token')
    const response = await axios.post(url + 'tpl-by-lvl-jur', {
      jurusan_id: jurusan.id,
      level_id: rombel.level_id
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
    setTemplate(response.data.data)
    setLoading(false)
  }


  const fetchSetting = async () => {
    setLoading(true)
    const token = localStorage.getItem('token')
    const response = await axios.get(url + 'setting', {
      headers: { Authorization: `Bearer ${token}` }
    })
    setSetting(response.data.data)
    setLoading(false)
  }

  const fetchKepsek = async () => {
    setLoading(true)
    const token = localStorage.getItem('token')
    const response = await axios.get(url + 'kepsek', {
      headers: { Authorization: `Bearer ${token}` }
    })
    setKepsek(response.data.data)
    setLoading(false)
  }



  useEffect(() => {
    fetchSiswa()
    fetchRombel()
    fetchSemester()
    fetchKelompok()
    fetchSetting()
    fetchKepsek()
  }, [])

  useEffect(() => {
    fetchJurusan()
  }, [rombel])




  useEffect(() => {
    if (jurusan) {
      if (jurusan.kode_jurusan === 'TIL') {
        setProgram('Teknik Ketenagalistrikan');
      } else if (jurusan.kode_jurusan === 'TPm') {
        setProgram('Teknik Mesin');
      } else if (jurusan.kode_jurusan === 'TKRO' || jurusan.kode_jurusan === 'TBSM') {
        setProgram('Teknik Otomotif');
      } else if (jurusan.kode_jurusan === 'RPL') {
        setProgram('Pengembangan Perangkat Lunak dan Gim.');
      } else if (jurusan.kode_jurusan === 'TPL') {
        setProgram('Teknik Pengelasan dan Fabrikasi Logam');
      }
    }
  }, [jurusan]);

  useEffect(() => {
    if (jurusan) {
      fetchTemplate()
    }
  }, [jurusan]);




  useEffect(() => {
    if (rombel && rombel.level_id != null) {
      // Ensure rombel and level_id are not null or undefined
      if (rombel.level_id === "1") {
        if (semester && semester.id === 1) {
          setTampilSmt("1 (satu)");
        } else if (semester && semester.id === 2) {
          setTampilSmt("2 (dua)");
        }
      } else if (rombel.level_id === "2") {
        if (semester && semester.id === 1) {
          setTampilSmt("3 (tiga)");
        } else if (semester && semester.id === 2) {
          setTampilSmt("4 (empat)");
        }
      } else if (rombel.level_id === "3") {
        if (semester && semester.id === 1) {
          setTampilSmt("5 (lima)");
        } else if (semester && semester.id === 2) {
          setTampilSmt("6 (enam)");
        }
      }

      if (rombel.level_id === "1") {
        setFase("E");
      } else {
        setFase("F");
      }
    }
  }, [rombel, semester]);


  useEffect(() => {
    fetchKurikulum();
  }, [level]);

  useEffect(() => {
    fetchMode();
  }, []);




  const fetchNilai = async (subjectId, nis, rombelNama) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(url + 'get-nilai-by-name', {
        kdmp: subjectId,
        rombel: rombelNama,
        nis: nis
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching nilai:', error);
      return '';
    }
  };

  const fetchEkskul = async (nis, rombelNama) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(url + 'get-ekskul-rapor', {
        nis: nis,
        rombel: rombelNama
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching ekskul:', error);
      return [];
    }
  };

  const fetchAbsensi = async (nis, rombelNama) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(url + 'get-absensi', {
        nis: nis,
        rombel: rombelNama
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching absensi:', error);
      return { sakit: '-', izin: '-', tanpa_keterangan: '-' };
    }
  };

  const fetchPeringkat = async (nis, rombelNama) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        url + "get-peringkat",
        {
          nis: nis,
          rombel: rombelNama,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.data;
    } catch (error) {

    }
  };

  const fetchCatatanAkademik = async (nis, rombelNama) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        url + "get-catatan-akademik",
        {
          nis: nis,
          rombel: rombelNama,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching catatan akademik:", error);
      return [];
    }
  };


  const fetchPkl = async (nis, rombelNama) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        url + "get-pkl-by-siswa",
        {
          nis: nis,
          rombel: rombelNama,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching catatan akademik:", error);
      return [];
    }
  };



  const fetchJmlSiswaKelas = async (rombelNama) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        url + "get-jml-siswa-kls",
        {
          rombel: rombelNama,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.data;
    } catch (error) {

    }
  };

  const fetchJmlSiswaJurusan = async (rombelNama) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        url + "get-jml-siswa-jurusan",
        {
          rombel: rombelNama,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data.data;
    } catch (error) {

    }
  };






  const handleGeneratePDF = async (item) => {
    setLoadcetak(true)
    const doc = new jsPDF();

    doc.addFont('TimesNewRoman.ttf', 'TimesNewRoman', 'normal');
    doc.setFont('TimesNewRoman');

    const imgProps = doc.getImageProperties(imgData);
    const maxImgWidth = 190; // Set a maximum width for the image
    const imgWidth = Math.min(maxImgWidth, doc.internal.pageSize.width);
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width; // maintain aspect ratio
    const pageWidth = doc.internal.pageSize.width;

    doc.addImage(imgData, 'JPEG', 10, 10, imgWidth, imgHeight);

    const textStartY = 10 + imgHeight + 5;
    doc.setFontSize(12);

    doc.setFont('times', 'bold');
    doc.text(`LAPORAN HASIL BELAJAR SUMATIF TENGAH SEMESTER`, pageWidth / 2, textStartY, { align: 'center' });

    doc.setFont('times', 'normal');
    let currentY = textStartY + 7;
    const leftColumnX = 10;
    const middleColumnX = 50; // Adjust as needed for your layout
    const rightColumnX = 130; // Adjust as needed for your layout

    const maxTextWidth = 75; // Set a maximum width for text before wrapping

    // Nama Siswa
    const namaSiswaText = doc.splitTextToSize(`: ${item.nama}`, maxTextWidth).map((line, index) => index > 0 ? `  ${line}` : line);
    doc.text(`Nama Siswa`, leftColumnX, currentY);
    doc.text(namaSiswaText, middleColumnX, currentY);

    // Kelas
    const kelasText = doc.splitTextToSize(`: ${rombel.nama_rombel}.${item.no_abs}`, maxTextWidth);
    doc.text(`Kelas`, rightColumnX, currentY);
    doc.text(kelasText, rightColumnX + 30, currentY);
    currentY += Math.max(namaSiswaText.length, kelasText.length) * 5;

    // Nomor Induk / NISN
    const nisnText = doc.splitTextToSize(`: ${item.nis} ( ${item.nisn} )`, maxTextWidth);
    doc.text(`Nomor Induk / NISN`, leftColumnX, currentY);
    doc.text(nisnText, middleColumnX, currentY);

    // Fase
    const faseText = doc.splitTextToSize(`: ${fase}`, maxTextWidth);
    doc.text(`Fase`, rightColumnX, currentY);
    doc.text(faseText, rightColumnX + 30, currentY);
    currentY += Math.max(nisnText.length, faseText.length) * 5;

    // Program Keahlian
    const programText = doc.splitTextToSize(`: ${program}`, maxTextWidth);
    doc.text(`Program Keahlian`, leftColumnX, currentY);
    doc.text(programText, middleColumnX, currentY);

    // Semester
    const semesterText = doc.splitTextToSize(`: ${tampilSmt}`, maxTextWidth);
    doc.text(`Semester`, rightColumnX, currentY);
    doc.text(semesterText, rightColumnX + 30, currentY);
    currentY += Math.max(programText.length, semesterText.length) * 5;

    // Konsentrasi Keahlian
    const konsentrasiText = doc.splitTextToSize(`: ${rombel.jurusan}`, maxTextWidth);
    doc.text(`Konsentrasi Keahlian`, leftColumnX, currentY);
    doc.text(konsentrasiText, middleColumnX, currentY);

    // Tahun Pelajaran
    const tahunPelajaranText = doc.splitTextToSize(`: ${rombel.thn_pel}`, maxTextWidth);
    doc.text(`Tahun Pelajaran`, rightColumnX, currentY);
    doc.text(tahunPelajaranText, rightColumnX + 30, currentY);
    currentY += Math.max(konsentrasiText.length, tahunPelajaranText.length) * 5;


    currentY += 11;
    doc.setFont('times', 'bold');
    doc.text(`A. Intrakurikuler`, 10, currentY);

    const tableBody = [];

    if (template && Array.isArray(template)) {
      // Group the subjects by 'kelompok'
      const groupedSubjects = template.reduce((acc, item) => {
        const group = kelompok.find(k => k.id_klpk_mp === Number(item.kelompok));
        const groupName = group ? `${group.huruf} ${group.nama_kelompok}` : 'Unknown';

        const kelompokName = groupName || `Kelompok ${item.kelompok}`;
        if (!acc[kelompokName]) {
          acc[kelompokName] = [];
        }
        acc[kelompokName].push(item);
        return acc;
      }, {});

      // Iterate over the grouped subjects
      for (const groupKey of Object.keys(groupedSubjects)) {
        const group = groupedSubjects[groupKey];
        tableBody.push([{ content: groupKey, colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }]);

        const nilaiPromises = group.map(subject => fetchNilai(subject.mp, item.nis, rombel.nama_rombel));
        const nilaiResults = await Promise.allSettled(nilaiPromises);

        for (const [index, subject] of group.entries()) {
          const result = nilaiResults[index];
          const nilai = result.status === 'fulfilled' ? result.value : {};

          // Create a temporary div to render HTML content
          const tempDiv = document.createElement('div');
          const rawContent = nilai?.capaian || nilai?.capaian_min; // Define rawContent here

          // Check if the content is just a hyphen or a hyphen followed by whitespace
          // Remove hyphens that are not followed by any text
          if (rawContent) {
            tempDiv.innerHTML = rawContent.replace(/-\s*(<br\s*\/?>\s*)*(?=\s*<br\s*\/?>|$)/gi, '').replace(/(<br\s*\/?>\s*)+$/gi, '');
          } else {
            tempDiv.innerHTML = ''; // Set to empty if it's just a hyphen
          }
          document.body.appendChild(tempDiv);

          // Decode HTML entities to handle unique characters
          let plainText = he.decode(tempDiv.innerHTML);

          // Convert the HTML content to plain text, handling unique characters and preserving line breaks
          plainText = plainText
            .replace(/<br\s*\/?>/gi, '\n') // Replace <br> with newline
            .replace(/<\/p>/gi, '\n') // Replace </p> with newline
            .replace(/<[^>]+>/g, '') // Remove all HTML tags
            .normalize("NFD") // Normalize to remove diacritics
            .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
            .replace(/[^\w\s-]/g, ''); // Remove non-alphanumeric characters except spaces and -

          // Remove the temporary div
          document.body.removeChild(tempDiv);

          tableBody.push([
            subject.urutan,
            subject.nama_mp,
            { content: nilai?.olah_na || '', styles: { halign: 'center' } },
            { content: plainText }
          ]);
        }
      }
    } else {
      console.error('Template is not an array or is undefined');
    }

    // Section  Nilai
    doc.autoTable({
      head: [['No', 'Mata Pelajaran', 'Nilai Akhir', 'Capaian Kompetensi']],
      body: tableBody,
      startY: currentY + 7,
      margin: { left: 10, right: 10 },
      styles: {
        font: 'times',
        fontSize: 10,
        fillColor: null, // Remove background color
        lineWidth: 0.1,  // Set border width
        lineColor: [0, 0, 0], // Set border color to black
        cellWidth: 'wrap', // Enable text wrapping
        minCellHeight: 10, // Minimum cell height
        valign: 'top', // Vertical alignment
        overflow: 'linebreak', // Handle text overflow

      },
      headStyles: {
        font: 'times',
        fontSize: 12,
        fillColor: null, // Ensure header has no background color
        lineWidth: 0.1,  // Set border width for header
        lineColor: [0, 0, 0], // Set border color for header
        halign: 'center', // Center align the header text
        valign: 'middle'
      },
      columnStyles: {
        0: { cellWidth: 10 }, // No column
        1: { cellWidth: 70 }, // Mata Pelajaran column
        2: { cellWidth: 20 }, // Nilai Akhir column
        3: { cellWidth: 90 }, // Capaian Kompetensi column
      },
      theme: 'plain', // Use plain theme to avoid any default styling issues
      pageBreak: 'auto', // Automatically add page breaks
      tableWidth: 'wrap', // Ensure table width fits within the page
      tableWidth: 'auto',

    });

    // Add the additional sections
    let finalY = doc.lastAutoTable.finalY + 10;

    // Section B. Ekstrakurikuler


    const ekskulData = await fetchEkskul(item.nis, rombel.nama_rombel);

    // Check if there's enough space for the table, otherwise add a page break
    const pageHeight = doc.internal.pageSize.height;
    const marginBottom = 20; // Adjust as needed for your footer
    const tableHeightEstimate = ekskulData.length * 10 + 20; // Rough estimate of table height

    if (finalY + tableHeightEstimate > pageHeight - marginBottom) {
      doc.addPage();
      finalY = 10; // Reset Y position for the new page
    }

    doc.setFont('times', 'bold');
    doc.text('B. Ekstrakurikuler', 10, finalY);
    finalY += 5;

    const ekskulBody = Array.isArray(ekskulData)
      ? ekskulData.map(ekskul => [ekskul.nama_ekstra, ekskul.eks_ket_nilai])
      : ekskulData && typeof ekskulData === 'object'
        ? [[ekskulData?.nama_ekstra, ekskulData?.eks_ket_nilai]]
        : [['No data available', '']];


    doc.autoTable({
      startY: finalY,
      head: [['Kegiatan Ekstrakurikuler', 'Keterangan']],
      margin: { left: 10, right: 10 },
      body: ekskulBody.length ? ekskulBody : [['', '']],
      theme: 'plain',
      styles: {
        font: 'times',
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: 'wrap',
        minCellHeight: 10,
        valign: 'top',
        overflow: 'linebreak',
      },
      headStyles: {
        font: 'times',
        fontSize: 12,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: 'center',
        valign: 'middle'
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 130 },
      },
      theme: 'plain', // Use plain theme to avoid any default styling issues
      pageBreak: 'auto', // Automatically add page breaks
      tableWidth: 'wrap', // Ensure table width fits within the page
      tableWidth: 'auto',
    });

    finalY = doc.lastAutoTable.finalY + 10;

    // Section C. Ketidakhadiran

    // Fetch absensi data
    const absensiData = await fetchAbsensi(item.nis, rombel.nama_rombel);

    // Estimate the height of the Ketidakhadiran section and signatures
    const ketidakhadiranHeight = 40; // Adjust this value based on your content
    const signaturesHeight = 60; // Adjust this value based on your content

    // Check if there's enough space for the Ketidakhadiran section and signatures
    const pageHeight_ = doc.internal.pageSize.height;
    const marginBottom_ = 20; // Adjust as needed for your footer
    if (finalY + ketidakhadiranHeight + signaturesHeight > pageHeight_ - marginBottom_) {
      doc.addPage();
      finalY = 10; // Reset Y position for the new page
    }

    doc.setFont('times', 'bold');
    doc.text('C. Ketidakhadiran', 10, finalY);
    finalY += 5;

    doc.autoTable({
      startY: finalY,
      margin: { left: 10, right: 10 },
      head: [['Jenis Ketidakhadiran', 'Keterangan']],
      body: [
        ['Sakit', `${absensiData?.sakit ?? '-'} hari`],
        ['Izin', `${absensiData?.izin ?? '-'} hari`],
        ['Tanpa Keterangan', `${absensiData?.alpa ?? '-'} hari`]
      ],
      theme: 'plain',
      styles: {
        font: 'times',
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: 'wrap',
        minCellHeight: 10,
        valign: 'top',
        overflow: 'linebreak',
      },
      headStyles: {
        font: 'times',
        fontSize: 12,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: 'center',
        valign: 'middle'
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 30 },
      },
      willDrawCell: (data) => {
        if (data.row.index === data.table.body.length - 1) {
          finalY = data.cursor.y + data.row.height + 10;
        }
      }
    });

    // Signatures and other text
    doc.setFont('times', 'normal');
    doc.text('Mengetahui', 10, finalY);
    doc.text('Orang Tua / Wali,', 10, finalY + 5);
    doc.text('____________________', 10, finalY + 30);

    doc.text('Ditetapkan di', 125, finalY);
    doc.text(`: ${setting?.tempat_cetak}`, 155, finalY);
    doc.text('Tanggal', 125, finalY + 5);
    doc.text(`: ${setting?.tgl_cetak}`, 155, finalY + 5);

    doc.text('Wali Kelas,', 125, finalY + 10);
    doc.text(`${rombel.name}`, 125, finalY + 30);
    const textWidth = doc.getTextWidth(`${rombel.name}`);
    doc.line(125, finalY + 31, 125 + textWidth, finalY + 31);

    finalY = finalY + 40;

    // ... existing code ...
    doc.text('Mengetahui,', pageWidth / 2, finalY, { align: 'center' });
    doc.text('Kepala Sekolah', pageWidth / 2, finalY + 5, { align: 'center' });
    doc.text(`${kepsek.kepsek}`, pageWidth / 2, finalY + 26, { align: 'center' });
    const textWidthks = doc.getTextWidth(`${kepsek.kepsek}`);

    doc.line((pageWidth / 2) - (textWidthks / 2), finalY + 27, (pageWidth / 2) + (textWidthks / 2), finalY + 27);


    // Ensure footer is added to every page
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const footerText = `Sumatif Tengah Semester, Halaman ke-${i} dari ${pageCount}`;
      doc.setFont('times');
      doc.setFontSize(10);
      doc.setFont(undefined, 'italic');
      doc.text(footerText, 10, doc.internal.pageSize.height - 10);
    }



    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
    setLoadcetak(false)
  };

  // Cetak bulk
  // const handleGeneratePDFBulk = async (item, doc = new jsPDF(), studentIndex = 0) => {
  const handleGeneratePDFBulk = async (item) => {

    const doc = new jsPDF();

    doc.addFont('TimesNewRoman.ttf', 'TimesNewRoman', 'normal');
    doc.setFont('TimesNewRoman');

    const imgProps = doc.getImageProperties(imgData);
    const maxImgWidth = 190; // Set a maximum width for the image
    const imgWidth = Math.min(maxImgWidth, doc.internal.pageSize.width);
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width; // maintain aspect ratio
    const pageWidth = doc.internal.pageSize.width;

    doc.addImage(imgData, 'JPEG', 10, 10, imgWidth, imgHeight);

    const textStartY = 10 + imgHeight + 5;
    doc.setFontSize(12);

    doc.setFont('times', 'bold');
    doc.text(`LAPORAN HASIL BELAJAR SUMATIF TENGAH SEMESTER`, pageWidth / 2, textStartY, { align: 'center' });

    doc.setFont('times', 'normal');
    let currentY = textStartY + 7;
    const leftColumnX = 10;
    const middleColumnX = 50; // Adjust as needed for your layout
    const rightColumnX = 130; // Adjust as needed for your layout

    const maxTextWidth = 75; // Set a maximum width for text before wrapping

    // Nama Siswa
    const namaSiswaText = doc.splitTextToSize(`: ${item.nama}`, maxTextWidth).map((line, index) => index > 0 ? `  ${line}` : line);
    doc.text(`Nama Siswa`, leftColumnX, currentY);
    doc.text(namaSiswaText, middleColumnX, currentY);

    // Kelas
    const kelasText = doc.splitTextToSize(`: ${rombel.nama_rombel}.${item.no_abs}`, maxTextWidth);
    doc.text(`Kelas`, rightColumnX, currentY);
    doc.text(kelasText, rightColumnX + 30, currentY);
    currentY += Math.max(namaSiswaText.length, kelasText.length) * 5;

    // Nomor Induk / NISN
    const nisnText = doc.splitTextToSize(`: ${item.nis} ( ${item.nisn} )`, maxTextWidth);
    doc.text(`Nomor Induk / NISN`, leftColumnX, currentY);
    doc.text(nisnText, middleColumnX, currentY);

    // Fase
    const faseText = doc.splitTextToSize(`: ${fase}`, maxTextWidth);
    doc.text(`Fase`, rightColumnX, currentY);
    doc.text(faseText, rightColumnX + 30, currentY);
    currentY += Math.max(nisnText.length, faseText.length) * 5;

    // Program Keahlian
    const programText = doc.splitTextToSize(`: ${program}`, maxTextWidth);
    doc.text(`Program Keahlian`, leftColumnX, currentY);
    doc.text(programText, middleColumnX, currentY);

    // Semester
    const semesterText = doc.splitTextToSize(`: ${tampilSmt}`, maxTextWidth);
    doc.text(`Semester`, rightColumnX, currentY);
    doc.text(semesterText, rightColumnX + 30, currentY);
    currentY += Math.max(programText.length, semesterText.length) * 5;

    // Konsentrasi Keahlian
    const konsentrasiText = doc.splitTextToSize(`: ${rombel.jurusan}`, maxTextWidth);
    doc.text(`Konsentrasi Keahlian`, leftColumnX, currentY);
    doc.text(konsentrasiText, middleColumnX, currentY);

    // Tahun Pelajaran
    const tahunPelajaranText = doc.splitTextToSize(`: ${rombel.thn_pel}`, maxTextWidth);
    doc.text(`Tahun Pelajaran`, rightColumnX, currentY);
    doc.text(tahunPelajaranText, rightColumnX + 30, currentY);
    currentY += Math.max(konsentrasiText.length, tahunPelajaranText.length) * 5;


    currentY += 11;
    doc.setFont('times', 'bold');
    doc.text(`A. Intrakurikuler`, 10, currentY);

    const tableBody = [];

    if (template && Array.isArray(template)) {
      // Group the subjects by 'kelompok'
      const groupedSubjects = template.reduce((acc, item) => {
        const group = kelompok.find(k => k.id_klpk_mp === Number(item.kelompok));
        const groupName = group ? `${group.huruf} ${group.nama_kelompok}` : 'Unknown';

        const kelompokName = groupName || `Kelompok ${item.kelompok}`;
        if (!acc[kelompokName]) {
          acc[kelompokName] = [];
        }
        acc[kelompokName].push(item);
        return acc;
      }, {});

      // Iterate over the grouped subjects
      for (const groupKey of Object.keys(groupedSubjects)) {
        const group = groupedSubjects[groupKey];
        tableBody.push([{ content: groupKey, colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }]);

        const nilaiPromises = group.map(subject => fetchNilai(subject.mp, item.nis, rombel.nama_rombel));
        const nilaiResults = await Promise.allSettled(nilaiPromises);

        for (const [index, subject] of group.entries()) {
          const result = nilaiResults[index];
          const nilai = result.status === 'fulfilled' ? result.value : '';

          // Create a temporary div to render HTML content
          const tempDiv = document.createElement('div');
          const rawContent = nilai?.capaian || nilai?.capaian_min; // Define rawContent here

          // Check if the content is just a hyphen or a hyphen followed by whitespace
          // Remove hyphens that are not followed by any text
          if (rawContent) {
            tempDiv.innerHTML = rawContent.replace(/-\s*(<br\s*\/?>\s*)*(?=\s*<br\s*\/?>|$)/gi, '').replace(/(<br\s*\/?>\s*)+$/gi, '');
          } else {
            tempDiv.innerHTML = ''; // Set to empty if it's just a hyphen
          }
          document.body.appendChild(tempDiv);

          // Decode HTML entities to handle unique characters
          let plainText = he.decode(tempDiv.innerHTML);

          // Convert the HTML content to plain text, handling unique characters and preserving line breaks
          plainText = plainText
            .replace(/<br\s*\/?>/gi, '\n') // Ganti <br> dengan newline
            .replace(/<\/p>/gi, '\n') // Ganti </p> dengan newline
            .replace(/<[^>]+>/g, '') // Hapus semua tag HTML
            .normalize("NFD") // Normalisasi untuk menghapus diacritics
            .replace(/[\u0300-\u036f]/g, '') // Hapus diacritics
            .replace(/[^\w\s-]/g, ''); // Hapus karakter non-alfanumerik kecuali spasi dan -
          // Remove the temporary div
          document.body.removeChild(tempDiv);

          tableBody.push([subject.urutan, subject.nama_mp, { content: nilai?.olah_na || '', styles: { halign: 'center' } }, { content: plainText }]);
        }
      }
    } else {
      console.error('Template is not an array or is undefined');
    }

    const initialPageCount = doc.internal.getNumberOfPages();

    // Section  Nilai
    doc.autoTable({
      head: [['No', 'Mata Pelajaran', 'Nilai Akhir', 'Capaian Kompetensi']],
      body: tableBody,
      startY: currentY + 7,
      margin: { left: 10, right: 10 },
      styles: {
        font: 'times',
        fontSize: 10,
        fillColor: null, // Remove background color
        lineWidth: 0.1,  // Set border width
        lineColor: [0, 0, 0], // Set border color to black
        cellWidth: 'wrap', // Enable text wrapping
        minCellHeight: 10, // Minimum cell height
        valign: 'top', // Vertical alignment
        overflow: 'linebreak', // Handle text overflow

      },
      headStyles: {
        font: 'times',
        fontSize: 12,
        fillColor: null, // Ensure header has no background color
        lineWidth: 0.1,  // Set border width for header
        lineColor: [0, 0, 0], // Set border color for header
        halign: 'center', // Center align the header text
        valign: 'middle'
      },
      columnStyles: {
        0: { cellWidth: 10 }, // No column
        1: { cellWidth: 70 }, // Mata Pelajaran column
        2: { cellWidth: 20 }, // Nilai Akhir column
        3: { cellWidth: 90 }, // Capaian Kompetensi column
      },
      theme: 'plain', // Use plain theme to avoid any default styling issues
      pageBreak: 'auto', // Automatically add page breaks
      tableWidth: 'wrap', // Ensure table width fits within the page
      tableWidth: 'auto',

    });

    // Add the additional sections
    let finalY = doc.lastAutoTable.finalY + 10;

    // Section B. Ekstrakurikuler

    const ekskulData = await fetchEkskul(item.nis, rombel.nama_rombel);


    // Check if there's enough space for the table, otherwise add a page break
    const pageHeight = doc.internal.pageSize.height;
    const marginBottom = 20; // Adjust as needed for your footer
    const tableHeightEstimate = ekskulData.length * 10 + 20; // Rough estimate of table height

    if (finalY + tableHeightEstimate > pageHeight - marginBottom) {
      doc.addPage();
      finalY = 10; // Reset Y position for the new page
    }


    doc.setFont('times', 'bold');
    doc.text('B. Ekstrakurikuler', 10, finalY);
    finalY += 5;


    const ekskulBody = Array.isArray(ekskulData)
      ? ekskulData.map(ekskul => [ekskul.nama_ekstra, ekskul.eks_ket_nilai])
      : ekskulData && typeof ekskulData === 'object'
        ? [[ekskulData?.nama_ekstra, ekskulData?.eks_ket_nilai]]
        : [['No data available', '']];


    doc.autoTable({
      startY: finalY,
      head: [['Kegiatan Ekstrakurikuler', 'Keterangan']],
      margin: { left: 10, right: 10 },
      body: ekskulBody.length ? ekskulBody : [['', '']],
      theme: 'plain',
      styles: {
        font: 'times',
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: 'wrap',
        minCellHeight: 10,
        valign: 'top',
        overflow: 'linebreak',
      },
      headStyles: {
        font: 'times',
        fontSize: 12,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: 'center',
        valign: 'middle'
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 130 },
      },
      theme: 'plain', // Use plain theme to avoid any default styling issues
      pageBreak: 'auto', // Automatically add page breaks
      tableWidth: 'wrap', // Ensure table width fits within the page
      tableWidth: 'auto',
    });

    finalY = doc.lastAutoTable.finalY + 10;

    // Section C. Ketidakhadiran

    // Fetch absensi data
    const absensiData = await fetchAbsensi(item.nis, rombel.nama_rombel);

    // Estimate the height of the Ketidakhadiran section and signatures
    const ketidakhadiranHeight = 40; // Adjust this value based on your content
    const signaturesHeight = 60; // Adjust this value based on your content

    // Check if there's enough space for the Ketidakhadiran section and signatures
    const pageHeight_ = doc.internal.pageSize.height;
    const marginBottom_ = 20; // Adjust as needed for your footer
    if (finalY + ketidakhadiranHeight + signaturesHeight > pageHeight_ - marginBottom_) {
      doc.addPage();
      finalY = 10; // Reset Y position for the new page
    }

    doc.setFont('times', 'bold');
    doc.text('C. Ketidakhadiran', 10, finalY);
    finalY += 5;

    doc.autoTable({
      startY: finalY,
      margin: { left: 10, right: 10 },
      head: [['Jenis Ketidakhadiran', 'Keterangan']],
      body: [
        ['Sakit', `${absensiData?.sakit ?? '-'} hari`],
        ['Izin', `${absensiData?.izin ?? '-'} hari`],
        ['Tanpa Keterangan', `${absensiData?.alpa ?? '-'} hari`]
      ],
      theme: 'plain',
      styles: {
        font: 'times',
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: 'wrap',
        minCellHeight: 10,
        valign: 'top',
        overflow: 'linebreak',
      },
      headStyles: {
        font: 'times',
        fontSize: 12,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: 'center',
        valign: 'middle'
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 30 },
      },
      willDrawCell: (data) => {
        if (data.row.index === data.table.body.length - 1) {
          finalY = data.cursor.y + data.row.height + 10;
        }
      }
    });

    // Signatures and other text
    doc.setFont('times', 'normal');
    doc.text('Mengetahui', 10, finalY);
    doc.text('Orang Tua / Wali,', 10, finalY + 5);
    doc.text('____________________', 10, finalY + 30);

    doc.text('Ditetapkan di', 125, finalY);
    doc.text(`: ${setting?.tempat_cetak}`, 155, finalY);
    doc.text('Tanggal', 125, finalY + 5);
    doc.text(`: ${setting?.tgl_cetak}`, 155, finalY + 5);

    doc.text('Wali Kelas,', 125, finalY + 10);
    doc.text(`${rombel.name}`, 125, finalY + 30);
    const textWidth = doc.getTextWidth(`${rombel.name}`);
    doc.line(125, finalY + 31, 125 + textWidth, finalY + 31);

    finalY = finalY + 40;

    // ... existing code ...
    doc.text('Mengetahui,', pageWidth / 2, finalY, { align: 'center' });
    doc.text('Kepala Sekolah', pageWidth / 2, finalY + 5, { align: 'center' });
    doc.text(`${kepsek.kepsek}`, pageWidth / 2, finalY + 26, { align: 'center' });
    const textWidthks = doc.getTextWidth(`${kepsek.kepsek}`);

    doc.line((pageWidth / 2) - (textWidthks / 2), finalY + 27, (pageWidth / 2) + (textWidthks / 2), finalY + 27);

    // Ensure footer is added to every page
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const footerText = `Sumatif Tengah Semester, Halaman ke-${i} dari ${pageCount}`;
      doc.setFont('times');
      doc.setFontSize(10);
      doc.setFont(undefined, 'italic');
      doc.text(footerText, 10, doc.internal.pageSize.height - 10);
    }

    return doc;

  };

  const handleCetakAll = async () => {
    setLoadAll(true);
    const mergedDoc = new jsPDF();

    for (const [index, item] of siswa.entries()) {
      const studentDoc = await handleGeneratePDFBulk(item);
      const pageCount = studentDoc.internal.getNumberOfPages();

      for (let i = 1; i <= pageCount; i++) {
        if (i > 1 || index > 0) {
          mergedDoc.addPage();
        }
        const pageContent = studentDoc.internal.pages[i];
        mergedDoc.internal.pages[mergedDoc.internal.getNumberOfPages()] = pageContent;

        // Add the image to each page
        const imgProps = mergedDoc.getImageProperties(imgData);
        const maxImgWidth = 190; // Set a maximum width for the image
        const imgWidth = Math.min(maxImgWidth, mergedDoc.internal.pageSize.width);
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width; // maintain aspect ratio
        mergedDoc.addImage(imgData, 'JPEG', 10, 10, imgWidth, imgHeight);

      }
    }

    const pdfBlob = mergedDoc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
    setLoadAll(false);
  };


  // Untuk K13
  const handleGeneratePDFK13 = async (item) => {
    setLoadcetak(true);
    const doc = new jsPDF();

    doc.addFont("TimesNewRoman.ttf", "TimesNewRoman", "normal");
    doc.setFont("TimesNewRoman");

    const imgProps = doc.getImageProperties(imgData);
    const maxImgWidth = 190; // Set a maximum width for the image
    const imgWidth = Math.min(maxImgWidth, doc.internal.pageSize.width);
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width; // maintain aspect ratio
    const pageWidth = doc.internal.pageSize.width;

    doc.addImage(imgData, "JPEG", 10, 10, imgWidth, imgHeight);

    const textStartY = 6 + imgHeight + 1;
    doc.setFontSize(12);

    doc.setFont("times", "normal");
    let currentY = textStartY + 7;
    const leftColumnX = 10;
    const middleColumnX = 50; // Adjust as needed for your layout
    const rightColumnX = 130; // Adjust as needed for your layout

    const maxTextWidth = 75; // Set a maximum width for text before wrapping


    // Nama Siswa
    const namaSiswaText = doc
      .splitTextToSize(`: ${item.nama}`, maxTextWidth)
      .map((line, index) => (index > 0 ? `  ${line}` : line));
    doc.text(`Nama Siswa`, leftColumnX, currentY);
    doc.text(namaSiswaText, middleColumnX, currentY);

    // Kelas
    const kelasText = doc.splitTextToSize(
      `: ${rombel.nama_rombel}.${item.no_abs}`,
      maxTextWidth
    );
    doc.text(`Kelas`, rightColumnX, currentY);
    doc.text(kelasText, rightColumnX + 30, currentY);
    currentY += Math.max(namaSiswaText.length, kelasText.length) * 5;

    // Nomor Induk / NISN
    const nisnText = doc.splitTextToSize(
      `: ${item.nis} ( ${item.nisn} )`,
      maxTextWidth
    );
    doc.text(`Nomor Induk / NISN`, leftColumnX, currentY);
    doc.text(nisnText, middleColumnX, currentY);

    // Semester
    const semesterText = doc.splitTextToSize(`: ${tampilSmt}`, maxTextWidth);
    doc.text(`Semester`, rightColumnX, currentY);
    doc.text(semesterText, rightColumnX + 30, currentY);
    currentY += Math.max(semesterText.length) * 5; // Adjusted currentY increment

    // Program Keahlian
    const programText = doc.splitTextToSize(`: ${program}`, maxTextWidth);
    doc.text(`Program Keahlian`, leftColumnX, currentY);
    doc.text(programText, middleColumnX, currentY);

    // Tahun Pelajaran
    const tahunPelajaranText = doc.splitTextToSize(
      `: ${rombel.thn_pel}`,
      maxTextWidth
    );
    doc.text(`Tahun Pelajaran`, rightColumnX, currentY);
    doc.text(tahunPelajaranText, rightColumnX + 30, currentY);
    currentY += Math.max(tahunPelajaranText.length) * 5; // Adjusted currentY increment

    // Konsentrasi Keahlian
    const konsentrasiText = doc.splitTextToSize(
      `: ${rombel.jurusan}`,
      maxTextWidth
    );
    doc.text(`Konsentrasi Keahlian`, leftColumnX, currentY);
    doc.text(konsentrasiText, middleColumnX, currentY);

    currentY += 8;
    doc.setFont("times", "bold");
    doc.text(`A. Pengetahuan dan Keterampilan`, 10, currentY);

    const tableBody = [];

    if (template && Array.isArray(template)) {
      // Group the subjects by 'kelompok'
      const groupedSubjects = template.reduce((acc, item) => {
        const group = kelompok.find(
          (k) => k.id_klpk_mp === Number(item.kelompok)
        );
        const groupName = group
          ? `${group.huruf} ${group.nama_kelompok}`
          : "Unknown";

        const kelompokName = groupName || `Kelompok ${item.kelompok}`;
        if (!acc[kelompokName]) {
          acc[kelompokName] = [];
        }
        acc[kelompokName].push(item);
        return acc;
      }, {});

      // Iterate over the grouped subjects
      for (const groupKey of Object.keys(groupedSubjects)) {
        const group = groupedSubjects[groupKey];
        tableBody.push([
          {
            content: groupKey,
            colSpan: 6,
            styles: { halign: "left", fontStyle: "bold" },
          },
        ]);

        const nilaiPromises = group.map((subject) =>
          fetchNilai(subject.mp, item.nis, rombel.nama_rombel)
        );
        const nilaiResults = await Promise.allSettled(nilaiPromises);

        for (const [index, subject] of group.entries()) {
          const result = nilaiResults[index];
          const nilai = result.status === "fulfilled" ? result.value : {};

          tableBody.push([
            subject.urutan,
            subject.nama_mp,
            { content: nilai?.olah_p || "", styles: { halign: "center" } },
            { content: nilai?.olah_k || "", styles: { halign: "center" } },
            { content: nilai?.olah_na || "", styles: { halign: "center" } },
            { content: nilai?.predikat || "", styles: { halign: "center", font: "times", minCellHeight: 0, fontSize: 10 } },

          ]);
        }
      }
    } else {
      console.error("Template is not an array or is undefined");
    }

    // Section  Nilai
    doc.autoTable({
      head: [
        [
          "No",
          "Mata Pelajaran",
          "Pengetahuan",
          "Keterampilan",
          "Nilai Akhir",
          "Predikat",
        ],
      ],
      body: tableBody,
      startY: currentY + 4,
      margin: { left: 10, right: 10 },
      styles: {
        font: "times",
        fontSize: 10,
        fillColor: null, // Remove background color
        lineWidth: 0.1, // Set border width
        lineColor: [0, 0, 0], // Set border color to black
        cellWidth: "wrap", // Enable text wrapping
        minCellHeight: 0, // Minimum cell height
        valign: "top", // Vertical alignment
        overflow: "linebreak", // Handle text overflow
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null, // Ensure header has no background color
        lineWidth: 0.1, // Set border width for header
        lineColor: [0, 0, 0], // Set border color for header
        halign: "center", // Center align the header text
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 10 }, // No column
        1: { cellWidth: 70 }, // Mata Pelajaran column
        2: { cellWidth: 30 }, // Pengetahuan
        3: { cellWidth: 30 }, // Keterampilan
        4: { cellWidth: 30 }, // NA
        5: { cellWidth: 20 }, // Predikat
      },
      theme: "plain", // Use plain theme to avoid any default styling issues
      pageBreak: "auto", // Automatically add page breaks
      tableWidth: "wrap", // Ensure table width fits within the page
      tableWidth: "auto",
    });

    // Add the additional sections
    let finalY = doc.lastAutoTable.finalY + 6;

    // Section B. Catatan Akademik

    const CatatanAkademikData = await fetchCatatanAkademik(
      item.nis,
      rombel.nama_rombel
    );

    // Check if there's enough space for the table, otherwise add a page break
    const pageHeight = doc.internal.pageSize.height;
    const marginBottom = 20; // Adjust as needed for your footer
    const tableHeightEstimate = CatatanAkademikData.length * 10 + 20; // Rough estimate of table height

    if (finalY + tableHeightEstimate > pageHeight - marginBottom) {
      doc.addPage();
      finalY = 10; // Reset Y position for the new page
    }

    doc.setFont("times", "bold");
    doc.text("B. Catatan Akademik", 10, finalY);
    finalY += 2;

    let catatanAkademikMessage = "";
    if (CatatanAkademikData.length === 0) {
      catatanAkademikMessage = "Ananda perlu mempertahankan kompetensi pengetahuan dan atau keterampilan sebagai bekal pembelajaran kompetensi kejuruan di semester / kelas berikutnya";
    } else if (CatatanAkademikData.length > 0) {
      const kdmpList = CatatanAkademikData.map((item) => item.kdmp).join(", ");
      catatanAkademikMessage = `Ananda perlu meningkatkan kompetensi pengetahuan dan atau keterampilan ${kdmpList} sebagai bekal pembelajaran kompetensi kejuruan di semester/kelas berikutnya.`;
    }

    const CatatanBody = [[catatanAkademikMessage]];


    doc.autoTable({
      startY: finalY,
      margin: { left: 10, right: 10 },
      body: CatatanBody,
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },

      columnStyles: {
        0: { cellWidth: 190 }, // Adjust the width as needed
      },
      theme: "plain",
      pageBreak: "auto",
      tableWidth: "wrap",
      tableWidth: "auto",
    });

    finalY = doc.lastAutoTable.finalY + 5;


    // Signatures and other text
    doc.setFont("times", "normal");
    doc.text("Mengetahui", 10, finalY);
    doc.text("Orang Tua / Wali,", 10, finalY + 5);
    doc.text("____________________", 10, finalY + 30);

    doc.text("Ditetapkan di", 125, finalY);
    doc.text(`: ${setting?.tempat_cetak}`, 155, finalY);
    doc.text("Tanggal", 125, finalY + 5);
    doc.text(`: ${setting?.tgl_cetak}`, 155, finalY + 5);

    doc.text("Wali Kelas,", 125, finalY + 10);
    doc.text(`${rombel.name}`, 125, finalY + 30);
    const textWidth_ = doc.getTextWidth(`${rombel.name}`);
    doc.line(125, finalY + 31, 125 + textWidth_, finalY + 31);

    finalY = finalY + 40;

    // ... existing code ...
    doc.text("Mengetahui,", pageWidth / 2, finalY, { align: "center" });
    doc.text("Kepala Sekolah", pageWidth / 2, finalY + 5, { align: "center" });
    doc.text(`${kepsek.kepsek}`, pageWidth / 2, finalY + 26, {
      align: "center",
    });
    const textWidthks_ = doc.getTextWidth(`${kepsek.kepsek}`);




    // Page 2

    // Fetch absensi data
    const PklData = await fetchPkl(item.nis, rombel.nama_rombel);


    const pklHeight = 40;
    const signaturesHeight = 60;

    const pageHeight_ = doc.internal.pageSize.height;
    const marginBottom_ = 20;
    if (
      finalY + pklHeight + signaturesHeight >
      pageHeight_ - marginBottom_
    ) {
      doc.addPage();
      finalY = 10;
    }

    // Add student information
    doc.setFont("times", "normal");
    doc.setFontSize(10);

    const leftMargin = 10;
    const rightMargin = 140;

    doc.text("Nama Siswa", leftMargin, finalY);
    doc.text(`: ${item.nama}`, leftMargin + 40, finalY);
    doc.text("Kelas", rightMargin, finalY);
    doc.text(`: ${rombel.nama_rombel}`, rightMargin + 40, finalY);

    finalY += 5;

    doc.text("Nomor Induk/NISN", leftMargin, finalY);
    doc.text(`: ${item.nis}/${item.nisn}`, leftMargin + 40, finalY);
    doc.text("Semester", rightMargin, finalY);
    doc.text(`${semesterText || ''}`, rightMargin + 40, finalY);

    finalY += 2; // Increased spacing before the line
    doc.setLineWidth(0.5); // Set line thickness (adjust as needed)
    doc.line(leftMargin, finalY, doc.internal.pageSize.width - leftMargin, finalY);

    finalY += 5; // Increased spacing after the line

    doc.setFont("times", "bold");
    doc.text("C. Praktik Kerja Lapangan", 10, finalY);
    finalY += 2;

    const PklBody = Array.isArray(PklData)
      ? PklData.map((pkl) => [pkl.nama_ekstra, pkl.eks_ket_nilai])
      : PklData && typeof PklData === "object"
        ? [[PklData?.nama_ekstra, PklData?.eks_ket_nilai]]
        : [["", ""]];


    doc.autoTable({
      startY: finalY,
      margin: { left: 10, right: 10 },
      head: [["No", "Nama DU/DI", "Lokasi", "Waktu Pelaksanaan", "Keterangan"]],
      body: PklBody.length ? PklBody : [["", ""]],
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 60 },
        2: { cellWidth: 60 },
        3: { cellWidth: 30 },
        4: { cellWidth: 30 },
      },
      willDrawCell: (data) => {
        if (data.row.index === data.table.body.length - 1) {
          finalY = data.cursor.y + data.row.height + 5;
        }
      },
    });

    // Ekskul

    doc.setFont("times", "bold");
    doc.text("D. Ekstrakurikuler", 10, finalY);
    finalY += 2;

    const ekskulData = await fetchEkskul(item.nis, rombel.nama_rombel);

    const ekskulBody = Array.isArray(ekskulData)
      ? ekskulData.map((ekskul) => [ekskul.nama_ekstra, ekskul.eks_ket_nilai])
      : ekskulData && typeof ekskulData === "object"
        ? [[ekskulData?.nama_ekstra, ekskulData?.eks_ket_nilai]]
        : [["No data available", ""]];


    doc.autoTable({
      startY: finalY,
      head: [["Kegiatan Ekstrakurikuler", "Keterangan"]],
      margin: { left: 10, right: 10 },
      body: ekskulBody.length ? ekskulBody : [["", ""]],
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 130 },
      },
      theme: "plain", // Use plain theme to avoid any default styling issues
      pageBreak: "auto", // Automatically add page breaks
      tableWidth: "wrap", // Ensure table width fits within the page
      tableWidth: "auto",
    });

    finalY = doc.lastAutoTable.finalY + 5;

    // Deskripsi Perkembangan Kareakter
    doc.setFont("times", "bold");
    doc.text("E. Deskripsi Perkembangan Karakter", 10, finalY);
    finalY += 2;

    doc.autoTable({
      startY: finalY,
      head: [["Karakter yang dibangun", "Deskripsi"]],
      margin: { left: 10, right: 10 },
      body: [
        [
          "Integritas",
          "Ananda menunjukkan kejujuran dengan tidak pernah melakukan plagiat dalam PR",
        ],
        [
          "Religius",
          "Ananda menunjukkan ketakwaan pada agama yang dianut"
        ],
        [
          "Nasionalis",
          "Ananda menunjukkan sikap nasionalis dengan tidak pernah melakukan pelanggaran ringan sesuai aturan di sekolah"
        ],
        [
          "Mandiri",
          "Ananda sering membantu temannya di lingkungan sekolah"
        ],
        [
          "Gotong-royong",
          "Ananda menunjukkan sikap gotong-royong sebagai relawan dalam kegiatan kerja bakti sekolah"
        ],
      ],
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 150 },
      },
      theme: "plain", // Use plain theme to avoid any default styling issues
      pageBreak: "auto", // Automatically add page breaks
      tableWidth: "wrap", // Ensure table width fits within the page
      tableWidth: "auto",
    });

    finalY = doc.lastAutoTable.finalY + 5;

    // Catatan Perkembangan Karakter
    doc.setFont("times", "bold");
    doc.text("F. Catatan Perkembangan Karakter", 10, finalY);
    finalY += 2;

    doc.autoTable({
      startY: finalY,

      margin: { left: 10, right: 10 },
      body: [
        [
          "Ananda memiliki perkembangan karakter relatif lengkap",
        ],
      ],
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },

      theme: "plain", // Use plain theme to avoid any default styling issues
      pageBreak: "auto", // Automatically add page breaks
      tableWidth: "wrap", // Ensure table width fits within the page
      tableWidth: "auto",
    });

    finalY = doc.lastAutoTable.finalY + 5;


    // Ketidakhadiran
    doc.setFont("times", "bold");
    doc.text("G. Ketidakhadiran", 10, finalY);
    finalY += 2;

    const absensiData = await fetchAbsensi(item.nis, rombel.nama_rombel);


    doc.autoTable({
      startY: finalY,
      head: [["Jenis Ketidakhadiran", "Keterangan"]],
      margin: { left: 10, right: 10 },
      body: [
        ["Sakit", `${absensiData?.sakit ?? "-"} hari`],
        ["Izin", `${absensiData?.izin ?? "-"} hari`],
        ["Tanpa Keterangan", `${absensiData?.alpa ?? "-"} hari`],
      ],
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 30 },
      },

      theme: "plain", // Use plain theme to avoid any default styling issues
      pageBreak: "auto", // Automatically add page breaks
      tableWidth: "wrap", // Ensure table width fits within the page
      tableWidth: "auto",
    });

    finalY = doc.lastAutoTable.finalY + 5;


    // Peringkat
    doc.setFont("times", "bold");
    doc.text("H. Peringkat", 10, finalY);
    finalY += 2;

    const peringkatData = await fetchPeringkat(item.nis, rombel.nama_rombel);
    const jmlSiswaKlsData = await fetchJmlSiswaKelas(rombel.nama_rombel);
    const jmlSiswaJurData = await fetchJmlSiswaJurusan(rombel.nama_rombel);


    doc.autoTable({
      startY: finalY,

      margin: { left: 10, right: 10 },
      body: [
        ["Jumlah Nilai Akhir", `${peringkatData?.jumlah ?? "-"} (${numberToWords(peringkatData?.jumlah || 0)})`],
        ["Rata-rata Nilai Akhir", (peringkatData?.avg != null)
          ? `${peringkatData.avg.toString().replace('.', ',')} (${numberToWords(peringkatData.avg)})`
          : "-"],
        ["Peringkat Kelas", `${peringkatData?.rank_kelas ?? "-"} (${numberToWords(peringkatData?.rank_kelas || 0)}) dari ${jmlSiswaKlsData ?? "-"} siswa`],

        ["Peringkat Kompetensi Keahlian", `${peringkatData?.rank_jurusan ?? "-"} (${numberToWords(peringkatData?.rank_jurusan || 0)}) dari ${jmlSiswaJurData ?? "-"} siswa`],
      ],
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 130 },
      },

      theme: "plain", // Use plain theme to avoid any default styling issues
      pageBreak: "auto", // Automatically add page breaks
      tableWidth: "wrap", // Ensure table width fits within the page
      tableWidth: "auto",
    });


    finalY = doc.lastAutoTable.finalY + 5;

    // Signatures and other text
    doc.setFont("times", "normal");
    doc.text("Mengetahui", 10, finalY);
    doc.text("Orang Tua / Wali,", 10, finalY + 5);
    doc.text("____________________", 10, finalY + 30);

    doc.text("Ditetapkan di", 125, finalY);
    doc.text(`: ${setting?.tempat_cetak}`, 155, finalY);
    doc.text("Tanggal", 125, finalY + 5);
    doc.text(`: ${setting?.tgl_cetak}`, 155, finalY + 5);

    doc.text("Wali Kelas,", 125, finalY + 10);
    doc.text(`${rombel.name}`, 125, finalY + 30);
    const textWidth = doc.getTextWidth(`${rombel.name}`);
    doc.line(125, finalY + 31, 125 + textWidth, finalY + 31);

    finalY = finalY + 35;

    // ... existing code ...
    doc.text("Mengetahui,", pageWidth / 2, finalY, { align: "center" });
    doc.text("Kepala Sekolah", pageWidth / 2, finalY + 5, { align: "center" });
    doc.text(`${kepsek.kepsek}`, pageWidth / 2, finalY + 26, {
      align: "center",
    });
    const textWidthks = doc.getTextWidth(`${kepsek.kepsek}`);

    doc.line(
      pageWidth / 2 - textWidthks / 2,
      finalY + 27,
      pageWidth / 2 + textWidthks / 2,
      finalY + 27
    );

    // Ensure footer is added to every page
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const footerText = `Rapor SMK Turen, Halaman ke-${i} dari ${pageCount}`;
      doc.setFont("times");
      doc.setFontSize(10);
      doc.setFont(undefined, "italic");
      doc.text(footerText, 10, doc.internal.pageSize.height - 10);

    }

    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
    setLoadcetak(false);
  };



  // Cetak Bulk K13
  const handleGeneratePDFBulkK13 = async (item) => {
    const doc = new jsPDF();

    doc.addFont("TimesNewRoman.ttf", "TimesNewRoman", "normal");
    doc.setFont("TimesNewRoman");

    const imgProps = doc.getImageProperties(imgData);
    const maxImgWidth = 190; // Set a maximum width for the image
    const imgWidth = Math.min(maxImgWidth, doc.internal.pageSize.width);
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width; // maintain aspect ratio
    const pageWidth = doc.internal.pageSize.width;

    doc.addImage(imgData, "JPEG", 10, 10, imgWidth, imgHeight);

    const textStartY = 6 + imgHeight + 1;
    doc.setFontSize(12);

    doc.setFont("times", "normal");
    let currentY = textStartY + 7;
    const leftColumnX = 10;
    const middleColumnX = 50; // Adjust as needed for your layout
    const rightColumnX = 130; // Adjust as needed for your layout

    const maxTextWidth = 75; // Set a maximum width for text before wrapping


    // Nama Siswa
    const namaSiswaText = doc
      .splitTextToSize(`: ${item.nama}`, maxTextWidth)
      .map((line, index) => (index > 0 ? `  ${line}` : line));
    doc.text(`Nama Siswa`, leftColumnX, currentY);
    doc.text(namaSiswaText, middleColumnX, currentY);

    // Kelas
    const kelasText = doc.splitTextToSize(
      `: ${rombel.nama_rombel}.${item.no_abs}`,
      maxTextWidth
    );
    doc.text(`Kelas`, rightColumnX, currentY);
    doc.text(kelasText, rightColumnX + 30, currentY);
    currentY += Math.max(namaSiswaText.length, kelasText.length) * 5;

    // Nomor Induk / NISN
    const nisnText = doc.splitTextToSize(
      `: ${item.nis} ( ${item.nisn} )`,
      maxTextWidth
    );
    doc.text(`Nomor Induk / NISN`, leftColumnX, currentY);
    doc.text(nisnText, middleColumnX, currentY);

    // Semester
    const semesterText = doc.splitTextToSize(`: ${tampilSmt}`, maxTextWidth);
    doc.text(`Semester`, rightColumnX, currentY);
    doc.text(semesterText, rightColumnX + 30, currentY);
    currentY += Math.max(semesterText.length) * 5; // Adjusted currentY increment

    // Program Keahlian
    const programText = doc.splitTextToSize(`: ${program}`, maxTextWidth);
    doc.text(`Program Keahlian`, leftColumnX, currentY);
    doc.text(programText, middleColumnX, currentY);

    // Tahun Pelajaran
    const tahunPelajaranText = doc.splitTextToSize(
      `: ${rombel.thn_pel}`,
      maxTextWidth
    );
    doc.text(`Tahun Pelajaran`, rightColumnX, currentY);
    doc.text(tahunPelajaranText, rightColumnX + 30, currentY);
    currentY += Math.max(tahunPelajaranText.length) * 5; // Adjusted currentY increment

    // Konsentrasi Keahlian
    const konsentrasiText = doc.splitTextToSize(
      `: ${rombel.jurusan}`,
      maxTextWidth
    );
    doc.text(`Konsentrasi Keahlian`, leftColumnX, currentY);
    doc.text(konsentrasiText, middleColumnX, currentY);

    currentY += 8;
    doc.setFont("times", "bold");
    doc.text(`A. Pengetahuan dan Keterampilan`, 10, currentY);

    const tableBody = [];

    if (template && Array.isArray(template)) {
      // Group the subjects by 'kelompok'
      const groupedSubjects = template.reduce((acc, item) => {
        const group = kelompok.find(
          (k) => k.id_klpk_mp === Number(item.kelompok)
        );
        const groupName = group
          ? `${group.huruf} ${group.nama_kelompok}`
          : "Unknown";

        const kelompokName = groupName || `Kelompok ${item.kelompok}`;
        if (!acc[kelompokName]) {
          acc[kelompokName] = [];
        }
        acc[kelompokName].push(item);
        return acc;
      }, {});

      // Iterate over the grouped subjects
      for (const groupKey of Object.keys(groupedSubjects)) {
        const group = groupedSubjects[groupKey];
        tableBody.push([
          {
            content: groupKey,
            colSpan: 6,
            styles: { halign: "left", fontStyle: "bold" },
          },
        ]);

        const nilaiPromises = group.map((subject) =>
          fetchNilai(subject.mp, item.nis, rombel.nama_rombel)
        );
        const nilaiResults = await Promise.allSettled(nilaiPromises);

        for (const [index, subject] of group.entries()) {
          const result = nilaiResults[index];
          const nilai = result.status === "fulfilled" ? result.value : {};

          tableBody.push([
            subject.urutan,
            subject.nama_mp,
            { content: nilai?.olah_p || "", styles: { halign: "center" } },
            { content: nilai?.olah_k || "", styles: { halign: "center" } },
            { content: nilai?.olah_na || "", styles: { halign: "center" } },
            { content: nilai?.predikat || "", styles: { halign: "center", font: "times", minCellHeight: 0, fontSize: 10 } },

          ]);
        }
      }
    } else {
      console.error("Template is not an array or is undefined");
    }

    // Section  Nilai
    doc.autoTable({
      head: [
        [
          "No",
          "Mata Pelajaran",
          "Pengetahuan",
          "Keterampilan",
          "Nilai Akhir",
          "Predikat",
        ],
      ],
      body: tableBody,
      startY: currentY + 4,
      margin: { left: 10, right: 10 },
      styles: {
        font: "times",
        fontSize: 10,
        fillColor: null, // Remove background color
        lineWidth: 0.1, // Set border width
        lineColor: [0, 0, 0], // Set border color to black
        cellWidth: "wrap", // Enable text wrapping
        minCellHeight: 0, // Minimum cell height
        valign: "top", // Vertical alignment
        overflow: "linebreak", // Handle text overflow
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null, // Ensure header has no background color
        lineWidth: 0.1, // Set border width for header
        lineColor: [0, 0, 0], // Set border color for header
        halign: "center", // Center align the header text
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 10 }, // No column
        1: { cellWidth: 70 }, // Mata Pelajaran column
        2: { cellWidth: 30 }, // Pengetahuan
        3: { cellWidth: 30 }, // Keterampilan
        4: { cellWidth: 30 }, // NA
        5: { cellWidth: 20 }, // Predikat
      },
      theme: "plain", // Use plain theme to avoid any default styling issues
      pageBreak: "auto", // Automatically add page breaks
      tableWidth: "wrap", // Ensure table width fits within the page
      tableWidth: "auto",
    });

    // Add the additional sections
    let finalY = doc.lastAutoTable.finalY + 6;

    // Section B. Catatan Akademik

    const CatatanAkademikData = await fetchCatatanAkademik(
      item.nis,
      rombel.nama_rombel
    );

    // Check if there's enough space for the table, otherwise add a page break
    const pageHeight = doc.internal.pageSize.height;
    const marginBottom = 20; // Adjust as needed for your footer
    const tableHeightEstimate = CatatanAkademikData.length * 10 + 20; // Rough estimate of table height

    if (finalY + tableHeightEstimate > pageHeight - marginBottom) {
      doc.addPage();
      finalY = 10; // Reset Y position for the new page
    }

    doc.setFont("times", "bold");
    doc.text("B. Catatan Akademik", 10, finalY);
    finalY += 2;

    let catatanAkademikMessage = "";
    if (CatatanAkademikData.length === 0) {
      catatanAkademikMessage = "Ananda perlu mempertahankan kompetensi pengetahuan dan atau keterampilan sebagai bekal pembelajaran kompetensi kejuruan di semester / kelas berikutnya";
    } else if (CatatanAkademikData.length > 0) {
      const kdmpList = CatatanAkademikData.map((item) => item.kdmp).join(", ");
      catatanAkademikMessage = `Ananda perlu meningkatkan kompetensi pengetahuan dan atau keterampilan ${kdmpList} sebagai bekal pembelajaran kompetensi kejuruan di semester/kelas berikutnya.`;
    }

    const CatatanBody = [[catatanAkademikMessage]];


    doc.autoTable({
      startY: finalY,
      margin: { left: 10, right: 10 },
      body: CatatanBody,
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },

      columnStyles: {
        0: { cellWidth: 190 }, // Adjust the width as needed
      },
      theme: "plain",
      pageBreak: "auto",
      tableWidth: "wrap",
      tableWidth: "auto",
    });

    finalY = doc.lastAutoTable.finalY + 5;


    // Signatures and other text
    doc.setFont("times", "normal");
    doc.text("Mengetahui", 10, finalY);
    doc.text("Orang Tua / Wali,", 10, finalY + 5);
    doc.text("____________________", 10, finalY + 30);

    doc.text("Ditetapkan di", 125, finalY);
    doc.text(`: ${setting?.tempat_cetak}`, 155, finalY);
    doc.text("Tanggal", 125, finalY + 5);
    doc.text(`: ${setting?.tgl_cetak}`, 155, finalY + 5);

    doc.text("Wali Kelas,", 125, finalY + 10);
    doc.text(`${rombel.name}`, 125, finalY + 30);
    const textWidth_ = doc.getTextWidth(`${rombel.name}`);
    doc.line(125, finalY + 31, 125 + textWidth_, finalY + 31);

    finalY = finalY + 40;

    // ... existing code ...
    doc.text("Mengetahui,", pageWidth / 2, finalY, { align: "center" });
    doc.text("Kepala Sekolah", pageWidth / 2, finalY + 5, { align: "center" });
    doc.text(`${kepsek.kepsek}`, pageWidth / 2, finalY + 26, {
      align: "center",
    });
    const textWidthks_ = doc.getTextWidth(`${kepsek.kepsek}`);




    // Page 2

    // Fetch absensi data
    const PklData = await fetchPkl(item.nis, rombel.nama_rombel);


    const pklHeight = 40;
    const signaturesHeight = 60;

    const pageHeight_ = doc.internal.pageSize.height;
    const marginBottom_ = 20;
    if (
      finalY + pklHeight + signaturesHeight >
      pageHeight_ - marginBottom_
    ) {
      doc.addPage();
      finalY = 10;
    }

    // Add student information
    doc.setFont("times", "normal");
    doc.setFontSize(10);

    const leftMargin = 10;
    const rightMargin = 140;

    doc.text("Nama Siswa", leftMargin, finalY);
    doc.text(`: ${item.nama}`, leftMargin + 40, finalY);
    doc.text("Kelas", rightMargin, finalY);
    doc.text(`: ${rombel.nama_rombel}`, rightMargin + 40, finalY);

    finalY += 5;

    doc.text("Nomor Induk/NISN", leftMargin, finalY);
    doc.text(`: ${item.nis}/${item.nisn}`, leftMargin + 40, finalY);
    doc.text("Semester", rightMargin, finalY);
    doc.text(`${semesterText || ''}`, rightMargin + 40, finalY);

    finalY += 2; // Increased spacing before the line
    doc.setLineWidth(0.5); // Set line thickness (adjust as needed)
    doc.line(leftMargin, finalY, doc.internal.pageSize.width - leftMargin, finalY);

    finalY += 5; // Increased spacing after the line

    doc.setFont("times", "bold");
    doc.text("C. Praktik Kerja Lapangan", 10, finalY);
    finalY += 2;

    const PklBody = Array.isArray(PklData)
      ? PklData.map((pkl) => [pkl.nama_ekstra, pkl.eks_ket_nilai])
      : PklData && typeof PklData === "object"
        ? [[PklData?.nama_ekstra, PklData?.eks_ket_nilai]]
        : [["", ""]];


    doc.autoTable({
      startY: finalY,
      margin: { left: 10, right: 10 },
      head: [["No", "Nama DU/DI", "Lokasi", "Waktu Pelaksanaan", "Keterangan"]],
      body: PklBody.length ? PklBody : [["", ""]],
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 60 },
        2: { cellWidth: 60 },
        3: { cellWidth: 30 },
        4: { cellWidth: 30 },
      },
      willDrawCell: (data) => {
        if (data.row.index === data.table.body.length - 1) {
          finalY = data.cursor.y + data.row.height + 5;
        }
      },
    });

    // Ekskul

    doc.setFont("times", "bold");
    doc.text("D. Ekstrakurikuler", 10, finalY);
    finalY += 2;

    const ekskulData = await fetchEkskul(item.nis, rombel.nama_rombel);

    const ekskulBody = Array.isArray(ekskulData)
      ? ekskulData.map((ekskul) => [ekskul.nama_ekstra, ekskul.eks_ket_nilai])
      : ekskulData && typeof ekskulData === "object"
        ? [[ekskulData?.nama_ekstra, ekskulData?.eks_ket_nilai]]
        : [["No data available", ""]];


    doc.autoTable({
      startY: finalY,
      head: [["Kegiatan Ekstrakurikuler", "Keterangan"]],
      margin: { left: 10, right: 10 },
      body: ekskulBody.length ? ekskulBody : [["", ""]],
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 130 },
      },
      theme: "plain", // Use plain theme to avoid any default styling issues
      pageBreak: "auto", // Automatically add page breaks
      tableWidth: "wrap", // Ensure table width fits within the page
      tableWidth: "auto",
    });

    finalY = doc.lastAutoTable.finalY + 5;

    // Deskripsi Perkembangan Kareakter
    doc.setFont("times", "bold");
    doc.text("E. Deskripsi Perkembangan Karakter", 10, finalY);
    finalY += 2;

    doc.autoTable({
      startY: finalY,
      head: [["Karakter yang dibangun", "Deskripsi"]],
      margin: { left: 10, right: 10 },
      body: [
        [
          "Integritas",
          "Ananda menunjukkan kejujuran dengan tidak pernah melakukan plagiat dalam PR",
        ],
        [
          "Religius",
          "Ananda menunjukkan ketakwaan pada agama yang dianut"
        ],
        [
          "Nasionalis",
          "Ananda menunjukkan sikap nasionalis dengan tidak pernah melakukan pelanggaran ringan sesuai aturan di sekolah"
        ],
        [
          "Mandiri",
          "Ananda sering membantu temannya di lingkungan sekolah"
        ],
        [
          "Gotong-royong",
          "Ananda menunjukkan sikap gotong-royong sebagai relawan dalam kegiatan kerja bakti sekolah"
        ],
      ],
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 150 },
      },
      theme: "plain", // Use plain theme to avoid any default styling issues
      pageBreak: "auto", // Automatically add page breaks
      tableWidth: "wrap", // Ensure table width fits within the page
      tableWidth: "auto",
    });

    finalY = doc.lastAutoTable.finalY + 5;

    // Catatan Perkembangan Karakter
    doc.setFont("times", "bold");
    doc.text("F. Catatan Perkembangan Karakter", 10, finalY);
    finalY += 2;

    doc.autoTable({
      startY: finalY,

      margin: { left: 10, right: 10 },
      body: [
        [
          "Ananda memiliki perkembangan karakter relatif lengkap",
        ],
      ],
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },

      theme: "plain", // Use plain theme to avoid any default styling issues
      pageBreak: "auto", // Automatically add page breaks
      tableWidth: "wrap", // Ensure table width fits within the page
      tableWidth: "auto",
    });

    finalY = doc.lastAutoTable.finalY + 5;


    // Ketidakhadiran
    doc.setFont("times", "bold");
    doc.text("G. Ketidakhadiran", 10, finalY);
    finalY += 2;

    const absensiData = await fetchAbsensi(item.nis, rombel.nama_rombel);


    doc.autoTable({
      startY: finalY,
      head: [["Jenis Ketidakhadiran", "Keterangan"]],
      margin: { left: 10, right: 10 },
      body: [
        ["Sakit", `${absensiData?.sakit ?? "-"} hari`],
        ["Izin", `${absensiData?.izin ?? "-"} hari`],
        ["Tanpa Keterangan", `${absensiData?.alpa ?? "-"} hari`],
      ],
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 30 },
      },

      theme: "plain", // Use plain theme to avoid any default styling issues
      pageBreak: "auto", // Automatically add page breaks
      tableWidth: "wrap", // Ensure table width fits within the page
      tableWidth: "auto",
    });

    finalY = doc.lastAutoTable.finalY + 5;


    // Peringkat
    doc.setFont("times", "bold");
    doc.text("H. Peringkat", 10, finalY);
    finalY += 2;

    const peringkatData = await fetchPeringkat(item.nis, rombel.nama_rombel);
    const jmlSiswaKlsData = await fetchJmlSiswaKelas(rombel.nama_rombel);
    const jmlSiswaJurData = await fetchJmlSiswaJurusan(rombel.nama_rombel);


    doc.autoTable({
      startY: finalY,

      margin: { left: 10, right: 10 },
      body: [
        ["Jumlah Nilai Akhir", `${peringkatData?.jumlah ?? "-"} (${numberToWords(peringkatData?.jumlah || 0)})`],
        ["Rata-rata Nilai Akhir", (peringkatData?.avg != null)
          ? `${peringkatData.avg.toString().replace('.', ',')} (${numberToWords(peringkatData.avg)})`
          : "-"],
        ["Peringkat Kelas", `${peringkatData?.rank_kelas ?? "-"} (${numberToWords(peringkatData?.rank_kelas || 0)}) dari ${jmlSiswaKlsData ?? "-"} siswa`],

        ["Peringkat Kompetensi Keahlian", `${peringkatData?.rank_jurusan ?? "-"} (${numberToWords(peringkatData?.rank_jurusan || 0)}) dari ${jmlSiswaJurData ?? "-"} siswa`],
      ],
      theme: "plain",
      styles: {
        font: "times",
        fontSize: 10,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        cellWidth: "wrap",
        minCellHeight: 0,
        valign: "top",
        overflow: "linebreak",
      },
      headStyles: {
        font: "times",
        fontSize: 10,
        fillColor: null,
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        halign: "center",
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 130 },
      },

      theme: "plain", // Use plain theme to avoid any default styling issues
      pageBreak: "auto", // Automatically add page breaks
      tableWidth: "wrap", // Ensure table width fits within the page
      tableWidth: "auto",
    });


    finalY = doc.lastAutoTable.finalY + 5;

    // Signatures and other text
    doc.setFont("times", "normal");
    doc.text("Mengetahui", 10, finalY);
    doc.text("Orang Tua / Wali,", 10, finalY + 5);
    doc.text("____________________", 10, finalY + 30);

    doc.text("Ditetapkan di", 125, finalY);
    doc.text(`: ${setting?.tempat_cetak}`, 155, finalY);
    doc.text("Tanggal", 125, finalY + 5);
    doc.text(`: ${setting?.tgl_cetak}`, 155, finalY + 5);

    doc.text("Wali Kelas,", 125, finalY + 10);
    doc.text(`${rombel.name}`, 125, finalY + 30);
    const textWidth = doc.getTextWidth(`${rombel.name}`);
    doc.line(125, finalY + 31, 125 + textWidth, finalY + 31);

    finalY = finalY + 35;

    // ... existing code ...
    doc.text("Mengetahui,", pageWidth / 2, finalY, { align: "center" });
    doc.text("Kepala Sekolah", pageWidth / 2, finalY + 5, { align: "center" });
    doc.text(`${kepsek.kepsek}`, pageWidth / 2, finalY + 26, {
      align: "center",
    });
    const textWidthks = doc.getTextWidth(`${kepsek.kepsek}`);

    doc.line(
      pageWidth / 2 - textWidthks / 2,
      finalY + 27,
      pageWidth / 2 + textWidthks / 2,
      finalY + 27
    );

    // Ensure footer is added to every page
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const footerText = `Rapor SMK Turen, Halaman ke-${i} dari ${pageCount}`;
      doc.setFont("times");
      doc.setFontSize(10);
      doc.setFont(undefined, "italic");
      doc.text(footerText, 10, doc.internal.pageSize.height - 10);

    }


    return doc;
  };


  const handleCetakAllK13 = async () => {
    setLoadAll(true);
    const mergedDoc = new jsPDF();

    for (const [index, item] of siswa.entries()) {
      const studentDoc = await handleGeneratePDFBulkK13(item);
      const pageCount = studentDoc.internal.getNumberOfPages();

      for (let i = 1; i <= pageCount; i++) {
        if (i > 1 || index > 0) {
          mergedDoc.addPage();
        }
        const pageContent = studentDoc.internal.pages[i];
        mergedDoc.internal.pages[mergedDoc.internal.getNumberOfPages()] =
          pageContent;

        // Add the image to each page
        const imgProps = mergedDoc.getImageProperties(imgData);
        const maxImgWidth = 190; // Set a maximum width for the image
        const imgWidth = Math.min(
          maxImgWidth,
          mergedDoc.internal.pageSize.width
        );
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width; // maintain aspect ratio
        mergedDoc.addImage(imgData, "JPEG", 10, 10, imgWidth, imgHeight);
      }
    }

    const pdfBlob = mergedDoc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
    setLoadAll(false);
  };













  function numberToWords(num) {
    const units = ['', 'satu', 'dua', 'tiga', 'empat', 'lima', 'enam', 'tujuh', 'delapan', 'sembilan'];
    const teens = ['sepuluh', 'sebelas', 'dua belas', 'tiga belas', 'empat belas', 'lima belas', 'enam belas', 'tujuh belas', 'delapan belas', 'sembilan belas'];
    const tens = ['', '', 'dua puluh', 'tiga puluh', 'empat puluh', 'lima puluh', 'enam puluh', 'tujuh puluh', 'delapan puluh', 'sembilan puluh'];

    function convertWholeNumber(n) {
      if (n === 0) return 'nol';
      if (n < 10) return units[n];
      if (n < 20) return teens[n - 10];
      if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 !== 0 ? ' ' + units[n % 10] : '');
      if (n < 1000) return units[Math.floor(n / 100)] + ' ratus' + (n % 100 !== 0 ? ' ' + convertWholeNumber(n % 100) : '');
      if (n < 1000000) return convertWholeNumber(Math.floor(n / 1000)) + ' ribu' + (n % 1000 !== 0 ? ' ' + convertWholeNumber(n % 1000) : '');
      return convertWholeNumber(Math.floor(n / 1000000)) + ' juta' + (n % 1000000 !== 0 ? ' ' + convertWholeNumber(n % 1000000) : '');
    }

    const [wholePart, decimalPart] = num.toString().split('.');
    let result = convertWholeNumber(parseInt(wholePart));

    if (decimalPart) {
      result += ' koma';
      for (let digit of decimalPart) {
        result += ' ' + units[parseInt(digit)];
      }
    }

    return result;
  }






  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content} - {nama_rombel}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">


            <div className="card-body">
              <div className="col-md-6 mb-3">
                <div className="btn-group btn-group-pill mt-2" role="group" aria-label="Basic example">
                  {loadAll ? (
                    <button className="btn btn-primary sm-btn" type="button" title="" disabled>
                      <Spinner animation="border" size="sm" />
                      <span> Loading...</span>
                    </button>
                  ) : kurikulum === '2' ? (
                    <button
                      className="btn btn-primary sm-btn"
                      type="button"
                      title=""
                      onClick={handleCetakAll}
                    >
                      Cetak All
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary sm-btn"
                      type="button"
                      title=""
                      onClick={handleCetakAllK13}
                    >
                      Cetak All
                    </button>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="table-responsive">
                  <table className="table table-styled mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>NIS</th>
                        <th>NISN</th>
                        <th>Nama </th>
                        <th>Agama</th>
                        <th>Status Nilai</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? <tr><td colSpan={7} style={{ textAlign: 'center' }}>Loading...</td></tr> :
                        siswa.sort((a, b) => a.no_abs - b.no_abs).map((item, index) => (
                          <tr key={index}>
                            <td style={{ padding: '5px', textAlign: 'center' }}>{index + 1}</td>
                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.nis}</td>
                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.nisn}</td>
                            <td style={{ padding: '5px' }}>{item.nama}</td>
                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.agama}</td>
                            <td></td>
                            <td>

                              {loadcetak ? (
                                <button className="btn btn-primary sm-btn" type="button" title="" disabled>
                                  <Spinner animation="border" size="sm" />
                                  <span> Loading...</span>
                                </button>
                              ) : kurikulum === "2" ? (
                                <button
                                  className="btn btn-primary sm-btn"
                                  type="button"
                                  title=""
                                  onClick={() => handleGeneratePDF(item)}
                                >
                                  Cetak
                                </button>
                              ) : (
                                <button
                                  className="btn btn-primary sm-btn"
                                  type="button"
                                  title=""
                                  onClick={() => handleGeneratePDFK13(item)}
                                >
                                  Cetak
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}



                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Raport
