import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Button, Modal, Form, Alert, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';

const DknKakomli = ({ url, content }) => {
  const { level, selectedJurusan, jur_id } = useParams();

  const [jurusan, setJurusan] = useState([]);
  // const [level, setLevel] = useState('');
  // const [selectedJurusan, setSelectedJurusan] = useState('');
  const [selectedRombel, setSelectedRombel] = useState('');
  const [rombel, setRombel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listMapel, setListMapel] = useState([]);
  const [siswa, setSiswa] = useState([]);
  const [nilai, setNilai] = useState({});
  const [totalNilai, setTotalNilai] = useState({});
  const [rankedSiswa, setRankedSiswa] = useState([]);
  const [rankKelas, setRankKelas] = useState([])
  const [selectedJurusanName, setSelectedJurusanName] = useState('');
  const [kakomli, setKakomli] = useState([]);


  const fetchKakomli = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(url + "get-kakomli", {
        email: localStorage.getItem("email")
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setKakomli(response.data.data);
      // setLevel(response.data.data.level);
      setJurusan(response.data.data.id);
      // setSelectedJurusan(response.data.data.kode_jurusan);
    } catch (error) {
      console.log(error);
    }
  };




  // const fetchJurusan = async () => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const response = await axios.get(url + "jurusan", {
  //       headers: { Authorization: `Bearer ${token}` },
  //     });
  //     setJurusan(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const fetchAllData = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      const response = await axios.post(url + 'get-dkn-jurusan', {
        jurusan: jur_id,
        level: level
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response.data);
      const { siswa, mapel, nilai, rank_kelas } = response.data;
      setSiswa(siswa);
      setListMapel(mapel);
      setNilai(nilai);
      setRankKelas(rank_kelas);
      calculateTotalNilai(nilai, siswa, mapel); // Pass siswa and mapel to calculateTotalNilai
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Simpan Total Nilai dan Peringkat
  const saveTotalNilaiDanPeringkat = async (totalNilaiData, rankedSiswa) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(url + 'save-jml-rank-jurusan', {
        totalNilai: totalNilaiData,
        peringkat_jurusan: rankedSiswa.map((s, index) => ({
          nis: s.nis,
          peringkat_jurusan: index + 1
        })),

      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Data saved successfully:', response.data);
    } catch (error) {
      console.log('Error saving data:', error);
    }
  };




  const calculateTotalNilai = (nilaiData, siswaData, mapelData) => {
    const totalNilaiData = {};
    siswaData.forEach(s => {
      let total = 0;
      mapelData.forEach(mapel => {
        const nilaiMapel = nilaiData[s.nis] && nilaiData[s.nis][mapel.mp] ? nilaiData[s.nis][mapel.mp] : 0;
        total += parseFloat(nilaiMapel);
      });
      totalNilaiData[s.nis] = total;
    });
    setTotalNilai(totalNilaiData);
    rankSiswa(totalNilaiData, siswaData, nilaiData);
  };

  const rankSiswa = (totalNilaiData, siswaData, nilaiData) => {
    const getAdditionalScore = (s, mapelNames) => {
      for (const mapelName of mapelNames) {
        if (nilaiData[s.nis] && nilaiData[s.nis][mapelName]) {
          return parseFloat(nilaiData[s.nis][mapelName].olah_na);
        }
      }
      return 0;
    };

    const ranked = [...siswaData].sort((a, b) => {
      const totalA = totalNilaiData[a.nis];
      const totalB = totalNilaiData[b.nis];

      if (totalA !== totalB) {
        return totalB - totalA;
      }

      // If total scores are the same, compare PAG or PAG_1 and PP
      const pagA = getAdditionalScore(a, ['PAG', 'PAG_1']);
      const pagB = getAdditionalScore(b, ['PAG', 'PAG_1']);
      if (pagA !== pagB) {
        return pagB - pagA;
      }

      const ppA = getAdditionalScore(a, ['PP']);
      const ppB = getAdditionalScore(b, ['PP']);
      return ppB - ppA;
    });

    setRankedSiswa(ranked);
    saveTotalNilaiDanPeringkat(totalNilaiData, ranked);
  };



  useEffect(() => {
    fetchKakomli();
  }, []);


  useEffect(() => {
    fetchAllData();
  }, [level, jur_id]);

  const exportToExcel = () => {
    const data = rankedSiswa.map((s, index) => {
      const row = {
        '#': index + 1,
        'NIS': s.nis,
        'Rombel': s.rombel,
        'Nama Siswa': s.nama,
      };
      // listMapel.forEach((mapel) => {
      //   row[mapel.mp] = nilai[s.nis] && nilai[s.nis][mapel.mp] ? nilai[s.nis][mapel.mp].olah_na : '-';
      // });
      listMapel.forEach((mapel) => {
        const value = nilai[s.nis] && nilai[s.nis][mapel.mp] ? nilai[s.nis][mapel.mp] : '-';
        console.log(`NIS: ${s.nis}, Mapel: ${mapel.mp}, Value: ${value}`);
        row[mapel.mp] = value;
      });
      row['Total Nilai'] = totalNilai[s.nis];
      row['Peringkat Kelas'] = rankKelas[s.nis];
      row['Peringkat Jurusan'] = isNaN(totalNilai[s.nis]) ? '-' : index + 1;
      return row;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, selectedJurusan); // Ensure nama_rombel is a string
    XLSX.writeFile(workbook, `DKN Jurusan ${selectedJurusan}.xlsx`); // Ensure nama_rombel is a string
  };





  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content} - {selectedJurusan} - Kelas:  {level === '1' ? '10' : level === '2' ? '11' : level === '3' ? '12' : level}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <Alert variant="warning" className='px-3'>
                <Alert.Heading>Info</Alert.Heading>
                <p>
                  Jika peringkat kelas masih kosong, silahkan hubungi wali kelas atau admin untuk melakukan export DKN/Legger terlebih dahulu.
                </p>
              </Alert>
            </div>

            <div className="card-body">

              <div className="col-12 mb-3">
                {!isLoading && (
                  <button onClick={exportToExcel} className="btn btn-primary sm-btn">Export to Excel</button>
                )}
              </div>


              <div className="row">
                <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '400px' }}>
                  <table className="table table-styled mb-0">
                    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                      <tr>
                        <th rowSpan={2}>#</th>
                        <th rowSpan={2}>NIS</th>
                        <th rowSpan={2}>Rombel</th>
                        <th rowSpan={2}>Nama Siswa</th>
                        <th colSpan={listMapel.length} className="text-center">MATA PELAJARAN</th>
                        <th rowSpan={2}>Total Nilai</th>
                        <th rowSpan={2}>Peringkat Kelas</th>
                        <th rowSpan={2}>Peringkat Jurusan</th>
                      </tr>
                      <tr>
                        {listMapel.map((mapel, index) => (
                          <th key={index} className='text-center'>{mapel.mp}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isLoading ? (
                          <Spinner animation="border" />
                        ) : (
                          rankedSiswa.map((s, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{s.nis}</td>
                              <td>{s.rombel}</td>
                              <td>{s.nama}</td>
                              {listMapel.map((mapel, mapelIndex) => (
                                <td key={mapelIndex} className='text-center'>
                                  {nilai[s.nis] && nilai[s.nis][mapel.mp] ? nilai[s.nis][mapel.mp] : '-'}
                                </td>
                              ))}
                              <td>{totalNilai[s.nis]}</td>
                              <td>{rankKelas[s.nis]}</td>
                              <td>{isNaN(totalNilai[s.nis]) ? '-' : index + 1}</td>
                            </tr>
                          ))
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DknKakomli
