import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ProgressBar, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx';


const DknKelas = ({ url, content }) => {
  const [jurusan, setJurusan] = useState([]);
  const [level, setLevel] = useState('');
  const [selectedJurusan, setSelectedJurusan] = useState('');
  const [selectedRombel, setSelectedRombel] = useState('');
  const [rombel, setRombel] = useState([])
  const [isLoading, setIsLoading] = useState([])
  const [listMapel, setListMapel] = useState([])
  const [siswa, setSiswa] = useState([])
  const [nilai, setNilai] = useState({});
  const [totalNilai, setTotalNilai] = useState({});
  const [rankedSiswa, setRankedSiswa] = useState([]);
  const [averageNilai, setAverageNilai] = useState({});


  const fetchJurusan = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(url + "jurusan", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setJurusan(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRombelbyId = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading
    try {
      const response = await axios.post(url + "rombel-by-jurusan", {
        jurusan_id: selectedJurusan,
        level_id: level
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRombel(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const fetchListMapel = async () => {
    const token = localStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.post(url + "get-list-mapel", {
        rombel: selectedRombel,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setListMapel(response.data.data);

    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSiswa = async () => {
    const token = localStorage.getItem('token');
    try {
      setIsLoading(true);
      const response = await axios.post(url + 'siswa-by-rombel', {
        rombel_nama: selectedRombel
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSiswa(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllNilai = async () => {
    if (siswa.length === 0 || listMapel.length === 0) {
      console.log('Siswa or ListMapel is empty');
      return;
    }

    const token = localStorage.getItem('token');
    const maxConcurrentRequests = 5; // Adjust this number based on your server's capacity
    const requests = [];
    const nilaiData = {};

    const fetchNilai = async (s, mapel) => {
      try {
        const response = await axios.post(url + 'get-nilai-by-name', {
          rombel: selectedRombel,
          kdmp: mapel.mp,
          nis: s.nis
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        return { nis: s.nis, mapel: mapel.mp, nilai: response.data.data };
      } catch (error) {
        console.log(error);
        return { nis: s.nis, mapel: mapel.mp, nilai: null };
      }
    };

    const processRequests = async () => {
      while (requests.length > 0) {
        const batch = requests.splice(0, maxConcurrentRequests);
        const results = await Promise.all(batch);
        results.forEach(({ nis, mapel, nilai }) => {
          if (!nilaiData[nis]) {
            nilaiData[nis] = {};
          }
          nilaiData[nis][mapel] = nilai ? nilai : {};
        });
      }
    };

    for (const s of siswa) {
      for (const mapel of listMapel) {
        requests.push(fetchNilai(s, mapel));
      }
    }

    try {
      setIsLoading(true);
      await processRequests();
      console.log('Fetched Nilai Data:', nilaiData); // Debugging line
      setNilai(nilaiData);
      calculateTotalNilai(nilaiData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  // Simpan Total Nilai dan Peringkat
  const saveTotalNilaiDanPeringkat = async (totalNilaiData, rankedSiswa, averageNilaiData) => {
    console.log('Total Nilai Data:', totalNilaiData);
    console.log('Ranked Siswa:', rankedSiswa);
    console.log('Average Nilai Data:', averageNilaiData);
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(url + 'save-jml-rank', {
        totalNilai: totalNilaiData,
        rata: averageNilaiData,
        peringkat: rankedSiswa.map((s, index) => ({
          nis: s.nis,
          peringkat: index + 1
        })),



        rombel: selectedRombel
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Data saved successfully:', response.data);
    } catch (error) {
      console.log('Error saving data:', error);
    }
  };


  const calculateTotalNilai = (nilaiData) => {
    const totalNilaiData = {};
    const averageNilaiData = {};
    siswa.forEach(s => {
      let total = 0;
      let validSubjects = 0;
      listMapel.forEach(mapel => {
        const nilaiMapel = nilaiData[s.nis] && nilaiData[s.nis][mapel.mp] ? parseFloat(nilaiData[s.nis][mapel.mp].olah_na) : 0;
        total += isNaN(nilaiMapel) ? 0 : nilaiMapel; // Ensure nilaiMapel is a valid number
        validSubjects++;
      });
      console.log('validSub', validSubjects)
      totalNilaiData[s.nis] = total;
      averageNilaiData[s.nis] = validSubjects > 0 ? (total / validSubjects).toFixed(2) : 0;
    });
    setTotalNilai(totalNilaiData);
    rankSiswa(totalNilaiData, averageNilaiData);

  };





  // const calculateTotalNilai = (nilaiData) => {
  //   const totalNilaiData = {};
  //   siswa.forEach(s => {
  //     let total = 0;
  //     listMapel.forEach(mapel => {
  //       const nilaiMapel = nilaiData[s.nis] && nilaiData[s.nis][mapel.mp] ? nilaiData[s.nis][mapel.mp].olah_na : 0;
  //       total += parseFloat(nilaiMapel);
  //     });
  //     totalNilaiData[s.nis] = total;
  //   });
  //   setTotalNilai(totalNilaiData);
  //   rankSiswa(totalNilaiData);
  // };

  // const rankSiswa = (totalNilaiData) => {
  //   const ranked = [...siswa].sort((a, b) => totalNilaiData[b.nis] - totalNilaiData[a.nis]);
  //   setRankedSiswa(ranked);
  // };

  const rankSiswa = (totalNilaiData, averageNilaiData) => {
    const getAdditionalScore = (s, mapelNames) => {
      for (const mapelName of mapelNames) {
        if (nilai[s.nis] && nilai[s.nis][mapelName]) {
          return parseFloat(nilai[s.nis][mapelName].olah_na);
        }
      }
      return 0;
    };

    const ranked = [...siswa].sort((a, b) => {
      const totalA = totalNilaiData[a.nis];
      const totalB = totalNilaiData[b.nis];

      if (totalA !== totalB) {
        return totalB - totalA;
      }

      // If total scores are the same, compare PAG or PAG_1 and PP
      const pagA = getAdditionalScore(a, ['PAG', 'PAG_1']);
      const pagB = getAdditionalScore(b, ['PAG', 'PAG_1']);
      if (pagA !== pagB) {
        return pagB - pagA;
      }

      const ppA = getAdditionalScore(a, ['PP']);
      const ppB = getAdditionalScore(b, ['PP']);
      return ppB - ppA;

      const avgDiff = averageNilaiData[b.nis] - averageNilaiData[a.nis];
      if (avgDiff !== 0) return avgDiff;

      // If averages are equal, use the total
      return totalNilaiData[b.nis] - totalNilaiData[a.nis];


    });

    setRankedSiswa(ranked);
    saveTotalNilaiDanPeringkat(totalNilaiData, ranked, averageNilaiData);
  };


  const exportToExcel = () => {
    const data = rankedSiswa.map((s, index) => {
      const row = {
        '#': index + 1,
        'NIS': s.nis,
        'Nama Siswa': s.nama,
      };
      listMapel.forEach((mapel) => {
        row[mapel.mp] = nilai[s.nis] && nilai[s.nis][mapel.mp] ? nilai[s.nis][mapel.mp].olah_na : '-';
      });
      row['Total Nilai'] = totalNilai[s.nis];
      row['Peringkat'] = isNaN(totalNilai[s.nis]) ? '-' : index + 1;
      return row;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, selectedRombel); // Ensure nama_rombel is a string
    XLSX.writeFile(workbook, `LEGGER ${selectedRombel}.xlsx`); // Ensure nama_rombel is a string
  };




  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await fetchAllNilai();
      setIsLoading(false)
    };
    fetchData();
  }, [siswa, listMapel]);




  useEffect(() => {
    fetchListMapel();
    fetchSiswa();
  }, [selectedRombel]);


  useEffect(() => {
    fetchRombelbyId(selectedJurusan, level);
  }, [selectedJurusan, level]);


  useEffect(() => {
    fetchJurusan()
    // fetchStatusLock();
  }, []);



  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="page-title-wrapper">
            <div className="page-title-box">
              <h4 className="page-title">{content}</h4>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-link">
                  <a href="/dashboard"><i className="fas fa-home mr-2"></i>Dashboard</a>
                </li>
                <li className="breadcrumb-link active">{content}</li>
              </ul>
            </div>
          </div>
          <div className="card">

            <div className="card-header">
              <div className="row">
                <div className="col-md-4">
                  <select className="form-control" onChange={(e) => {
                    setLevel(e.target.value);
                    setSelectedJurusan('');
                    setSelectedRombel('');
                  }}>
                    <option value="">Pilih Level / Kelas</option>
                    <option value="1">10</option>
                    <option value="2">11</option>
                    <option value="3">12</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <select className='form-control' value={selectedJurusan} onChange={(e) => {
                    setSelectedJurusan(e.target.value);
                    setSelectedRombel('');
                  }}>
                    <option value="">Pilih Jurusan</option>
                    {jurusan.map((j) => (
                      <option key={j.id} value={j.id}>{j.jurusan}</option>
                    ))}
                  </select>
                </div>

                <div className="col-md-4">
                  <select className='form-control' value={selectedRombel} onChange={(e) => setSelectedRombel(e.target.value)}>
                    <option value="">Pilih Rombel</option>
                    {rombel.map((r) => (
                      <option key={r.nama_rombel} value={r.nama_rombel}>{r.nama_rombel}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>


            <div className="card-body">
              <div className="row">

                <div className="col-12 mb-3">
                  {selectedRombel && !isLoading && (
                    <button onClick={exportToExcel} className="btn btn-primary sm-btn">Export to Excel</button>
                  )}
                </div>

                <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '400px' }}>

                  <table className="table table-styled mb-0">

                    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>

                      <tr>
                        <th rowSpan={2}>#</th>
                        <th rowSpan={2}>NIS</th>
                        <th rowSpan={2}>Nama Siswa</th>
                        <th colSpan={listMapel.length} className="text-center">MATA PELAJARAN</th>
                        <th rowSpan={2}>Total Nilai</th>
                        <th rowSpan={2}>Peringkat</th>
                      </tr>
                      <tr>
                        {listMapel.map((mapel, index) => (
                          <th key={index} className='text-center'>{mapel.mp}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {selectedRombel ? (
                        isLoading ? (
                          <Spinner animation="border" />
                        ) : (
                          rankedSiswa.map((s, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{s.nis}</td>
                              <td>{s.nama}</td>
                              {listMapel.map((mapel, mapelIndex) => (
                                <td key={mapelIndex} className='text-center'>
                                  {nilai[s.nis] && nilai[s.nis][mapel.mp] ? nilai[s.nis][mapel.mp].olah_na : '-'}
                                </td>
                              ))}
                              <td>{totalNilai[s.nis]}</td>
                              <td>{isNaN(totalNilai[s.nis]) ? '-' : index + 1}</td>
                            </tr>
                          ))
                        )
                      ) : (
                        <tr>
                          <td colSpan={listMapel.length + 5} className="text-center">
                            Silahkan pilih Level, Jurusan dan Rombel Terlebih Dahulu
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DknKelas
